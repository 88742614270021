import { useQuery, useQueryClient } from "react-query";

import { Appointment } from "@secondcloset/types/src/fulfillment";

import jwtDecode from "jwt-decode";

import { useGetToken } from "hooks/application/useGetToken";

import statusGroups from "constants/statusGroups";
import { API } from "lib/API";
import { getRoutePolylineCookie, setRoutePolylineCookie } from "lib/cookies";

import { JwtToken } from "interface/jwtToken";
import { Location } from "interface/location";

interface GetRoutePolylineBody {
  origin: string;
  destination: string;
  units?: "metric" | "imperial";
}

const fetchRoutePolyline = async (body: GetRoutePolylineBody, token?: string): Promise<string> => {
  const api = new API("pigeon", token);
  const { data } = await api.post("/google/route-poly-path", body);
  return data;
};

export const useRoutePolyline = () => {
  const queryClient = useQueryClient();
  const location = queryClient.getQueryData<Location>("location");
  const appointment = queryClient.getQueryData<Appointment>("appointment");

  const token = useGetToken();
  const decodedToken = token ? (jwtDecode(token) as JwtToken) : null;
  const appointmentId = decodedToken?.appointment_id;
  const routePolylineCookie = getRoutePolylineCookie(appointmentId as string);

  const getDestination = () => {
    if (appointment?.location?.coordinates?.lat && appointment?.location?.coordinates?.lng) {
      return `${appointment?.location?.coordinates?.lat},${appointment?.location?.coordinates?.lng}`;
    }
    return `${appointment?.location?.address}${appointment?.location?.city}${appointment?.location?.province}`;
  };

  return useQuery(
    "routePolyline",
    () => {
      return fetchRoutePolyline(
        {
          origin: `${location?.latitude},${location?.longitude}`,
          destination: getDestination(),
          units: "metric",
        },
        token
      );
    },
    {
      initialData: routePolylineCookie?.routePolyline,
      initialDataUpdatedAt: routePolylineCookie?.updatedAt,
      cacheTime: Infinity,
      staleTime: 1200000, // 20 minutes
      enabled:
        !!appointment &&
        statusGroups.onTheWay.includes(appointment?.status) &&
        !!location?.latitude &&
        !!location?.longitude,
      onSuccess: (routePolyline) => {
        setRoutePolylineCookie(routePolyline, appointment?.id as string);
      },
    }
  );
};
