import { useEffect } from "react";

import { debounce } from "lodash";

import { useSetRecoilState } from "recoil";
import { screenOrientationState } from "recoil/screenOrientation/atoms";

import { isLandscape } from "lib/isLandscape";

export const useScreenOrientation = () => {
  const setScreenOrientation = useSetRecoilState(screenOrientationState);

  const onWindowResize = debounce(() => {
    setScreenOrientation(isLandscape() ? "landscape" : "portrait");
  }, 50);

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    return () => window.removeEventListener("resize", onWindowResize);
  }, [onWindowResize]);
};
