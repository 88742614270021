import GoogleMapReact from "google-map-react";

import { GOOGLE_API_KEY } from "../env";
import { Address } from "../types";

import { Pin } from "./Pin";

const DEFAULT_CENTER = {
  lat: 47.441245,
  lng: -102.940783,
};

type AddressMapProps = {
  location: Address["coordinates"];
};

export const AddressMap = ({ location }: AddressMapProps) => {
  return (
    <GoogleMapReact
      zoom={location ? 18 : 3}
      center={location ?? DEFAULT_CENTER}
      bootstrapURLKeys={{
        key: GOOGLE_API_KEY,
        libraries: ["places"],
      }}
      options={{
        disableDefaultUI: true,
      }}
    >
      {location && <Pin lat={location.lat} lng={location.lng} />}
    </GoogleMapReact>
  );
};
