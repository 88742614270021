import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import XIcon from "@heroicons/react/outline/XIcon";
import { Button, Drawer, Form, Input, notification } from "antd";

import * as S from "./styles";
import { SCREEN_SIZES } from "styles/screenSizes";

import { useCreateDeliveryInstructions } from "hooks/api/useCreateDeliveryInstructions";

type DeliveryInstructionsDrawerProps = {
  open: boolean;
  trackingNumber: string;
  shouldShowWarning?: boolean;
  onClose: () => void;
};

type FormData = {
  access: string;
  details: string;
};

export const DeliveryInstructionsDrawer = ({
  open,
  trackingNumber,
  shouldShowWarning = false,
  onClose,
}: DeliveryInstructionsDrawerProps) => {
  const isMobile = useMediaQuery({ maxWidth: SCREEN_SIZES.sm - 1 });

  const [form] = Form.useForm();
  const accessValue = Form.useWatch("access", form);
  const detailsValue = Form.useWatch("details", form);

  const { t } = useTranslation();
  const { mutate: createDeliveryInstructions, isLoading: isCreatingDeliveryInstructions } =
    useCreateDeliveryInstructions({
      onSuccess: () => {
        form.resetFields();
        onClose();

        notification.success({
          message: t("Instructions have been sent successfully!"),
          closeIcon: <XIcon width={24} height={24} />,
        });
      },
      onError: () => {
        notification.error({
          message: t("Instructions could not be saved. Please try again."),
          closeIcon: <XIcon width={24} height={24} />,
        });
      },
    });

  const isAccessValueMissing = !accessValue;
  const isDetailsValueMissing = !detailsValue;

  return (
    <Drawer visible={open} width={isMobile ? "100vw" : 460} closable={false} bodyStyle={{ padding: 0 }}>
      <S.DrawerHeader>
        <S.DrawerTitle>{t("Add instructions")}</S.DrawerTitle>
        <S.DrawerCloseButton icon={<S.DrawerCloseButtonIcon />} onClick={onClose} />
      </S.DrawerHeader>

      <S.DrawerBody>
        {shouldShowWarning && (
          <S.Warning>
            <S.WarningIcon />
            {t(
              "Your delivery is set for today and may already be out of for delivery, any new instructions may not been seen by our delivery driver."
            )}
          </S.Warning>
        )}

        <Form
          form={form}
          layout="vertical"
          onFinish={(data: FormData) => {
            createDeliveryInstructions({
              ...data,
              tracking_number: trackingNumber,
            });
          }}
        >
          <Form.Item name="access" label={t("Access")}>
            <Input placeholder={t("Enter your access code")} aria-label="Access" />
          </Form.Item>

          <Form.Item name="details" label={t("Details")}>
            <Input.TextArea autoSize style={{ minHeight: 100 }} aria-label="Details" />
          </Form.Item>

          <Button
            block
            size="large"
            type="primary"
            htmlType="submit"
            disabled={isAccessValueMissing && isDetailsValueMissing}
            loading={isCreatingDeliveryInstructions}
          >
            {t("Submit")}
          </Button>
        </Form>
      </S.DrawerBody>
    </Drawer>
  );
};
