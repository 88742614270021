import styled from "styled-components";
import { COLORS, DEVICE } from "styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: ${COLORS.WHITE};
  padding: 24px;
  border-radius: 12px;
  font-weight: 400;

  @media ${DEVICE.lg} {
    padding: 32px;
    gap: 16px;
    width: 440px;
  }
`;

export const MainText = styled.h2`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;

  @media ${DEVICE.lg} {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const SubText = styled.span`
  font-size: 14px;
  line-height: 27px;
  color: ${COLORS.ONSURFACE_COPY};

  @media ${DEVICE.lg} {
    font-size: 16px;
    line-height: 29px;
  }
`;