import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

import { Appointment } from "@secondcloset/types/src/fulfillment";

import * as S from "./styles";
import { COLORS } from "styles";

import { useRecoilValue } from "recoil";
import { colorState } from "recoil/colourPicker/atoms";

import { Column, Row, Text } from "components/common";
import { AppointmentUtils } from "lib/AppointmentUtils";

import { MenuItem } from "../MenuItem";

export const Support: FC = () => {
  const color = useRecoilValue(colorState);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const appointment = queryClient.getQueryData<Appointment>("appointment");
  const isReloAppointment = AppointmentUtils.checkIsReloAppointment(appointment);

  return (
    <MenuItem title={t("Support")} renderIcon={() => <S.Phone width={24} color={color} />}>
      <Column padding="0 16px 16px">
        <Row alignItems="center" margin="8px 0 8px">
          <S.QuestionCircleIcon />
          <Text fontWeight={600} color={COLORS.BLUE} textDecoration="underline">
            <S.Link
              href={
                i18n.language === "en"
                  ? "https://help.gobolt.com/hc/en-us/articles/6203574600475-How-To-Prepare-For-Your-Delivery-or-Return-Appointment-"
                  : "https://help.gobolt.com/hc/fr-ca/articles/6203574600475-Comment-se-pr%C3%A9parer-%C3%A0-votre-rendez-vous-de-livraison-ou-de-retour-"
              }
              target="_blank"
            >
              {t(`How to prepare for your ${isReloAppointment ? "return" : "delivery"}`)}
            </S.Link>
          </Text>
        </Row>
        <Row alignItems="center" margin="8px 0 24px">
          <S.InfoCircleIcon />
          <Text fontWeight={600} color={COLORS.BLUE} textDecoration="underline">
            <S.Link href="https://boltlastmile.zendesk.com/hc/en-us" target="_blank">
              {t("Visit our help centre")}
            </S.Link>
          </Text>
        </Row>
      </Column>
    </MenuItem>
  );
};
