import { FC } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useQueryClient } from "react-query";

import { Appointment } from "@secondcloset/types/src/fulfillment";

import * as S from "./styles";
import { COLORS } from "styles";

import { useRecoilValue } from "recoil";
import { colorState } from "recoil/colourPicker/atoms";

import { Column, Row, Text } from "components/common";
import { AppointmentUtils } from "lib/AppointmentUtils";

import { MenuItem } from "../MenuItem";

export const OrderDetails: FC = () => {
  const color = useRecoilValue(colorState);
  const queryClient = useQueryClient();
  const appointment = queryClient.getQueryData<Appointment>("appointment");
  const { t } = useTranslation();

  const orderNumber = appointment?.shipment?.external_order?.external_order_number;
  const shipmentId = appointment?.shipment?.shipment_number;
  const organizationName = appointment?.shipment?.organization?.name;
  const deliveryMethod = AppointmentUtils.getDeliveryMethod({ appointment, t });

  return (
    <MenuItem title={t("Order Details")} renderIcon={() => <S.PackageIcon color={color} />}>
      <Column padding="0 16px 16px">
        <Row flex={1} margin="0 0 16px">
          <Column flex={1} margin="0 16px 0 0" maxWidth="50%">
            <Row margin="0 0 4px">
              <Text color={COLORS.GREY}>{t("Merchant")}</Text>
            </Row>
            <Text overflowWrap="anywhere">{organizationName || <Skeleton width={140} />}</Text>
          </Column>
          <Column flex={1}>
            <Row margin="0 0 4px">
              <Text color={COLORS.GREY}>{t("Service Level")}</Text>
            </Row>
            <Text overflowWrap="anywhere">{deliveryMethod || <Skeleton width={140} />}</Text>
          </Column>
        </Row>

        <Row flex={1} justifyContent="space-between">
          <Column flex={1} margin="0 16px 0 0" maxWidth="50%">
            <Row margin="0 0 4px">
              <Text color={COLORS.GREY}>{t("Order #")}</Text>
            </Row>
            <Text overflowWrap="anywhere">{orderNumber || <Skeleton width={140} />}</Text>
          </Column>
          <Column flex={1}>
            <Row margin="0 0 4px">
              <Text color={COLORS.GREY}>{t("Shipment ID")}</Text>
            </Row>
            <Text overflowWrap="anywhere">{shipmentId || <Skeleton width={140} />}</Text>
          </Column>
        </Row>
      </Column>
    </MenuItem>
  );
};
