import { useHistory, useRouteMatch } from "react-router";

import dayjs from "dayjs";
import jwtDecode from "jwt-decode";

import { useGetToken } from "./useGetToken";

import { JwtToken } from "interface/jwtToken";

export const useValidateToken = () => {
  const history = useHistory();
  const token = useGetToken();

  const isExemptedRoute = Boolean(useRouteMatch(["/", "/invalid-signature", "/tracking"]));
  if (isExemptedRoute) return;

  if (!token) return history.push("/");
  if (token.split(".").length !== 3) return history.push("/");

  const decodedToken = jwtDecode(token) as JwtToken;
  if (dayjs.unix(decodedToken.exp as number).isBefore(dayjs(Date.now()))) {
    history.push("/invalid-signature");
  }
  if (decodedToken.iss !== "secondcloset-galileo" || !decodedToken.aud?.includes("galileo")) {
    history.push("/");
  }
};
