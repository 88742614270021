import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory } from "react-router";

import { notification } from "antd";

import { fetchAppointment } from "hooks/api/fetchAppointment";

import { useToken } from "./useToken";

const ONE_MINUTE = 60 * 1000;

export const useAppointment = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const token = useToken();

  return useQuery({
    queryKey: ["appointment", token],
    queryFn: () => {
      if (!token) {
        throw new Error("Token is missing");
      }

      return fetchAppointment(token);
    },
    enabled: Boolean(token),
    staleTime: ONE_MINUTE,
    onError: () => {
      notification.error({
        message: t("Failed to fetch shipping information"),
      });

      history.push("/tracking");
    },
  });
};
