const statusGroups = {
  ready: ["request_received", "confirmed", "shipment_created", "scheduled", "staging", "ready"],
  loaded: ["loaded", "active"],
  onTheWay: ["on_the_way"],
  arrived: ["arrived", "started"],
  completed: ["done", "signed", "completed", "fulfilled"],
  failed: ["incomplete", "failed"],
};

export default statusGroups;
