import styled from "styled-components";

import { COLORS } from "styles";

export const LanguageSwitcherWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 16px 0 8px;
  cursor: pointer;
  font-size: 14px;
`;

interface LanguageProps {
  active: boolean;
  padding?: string;
  color: string;
  hoverColor: string;
}

export const Language = styled.div<LanguageProps>`
  padding: ${({ padding }) => (padding ? padding : 0)};
  color: ${(props) => props.color};
  font-weight: ${({ active }) => (active ? 600 : 400)};
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    color: ${(props) => props.hoverColor};
  }
`;

export const Divider = styled.div`
  margin: 0 8px;
`;
