import { useMutation } from "react-query";
import { API } from "lib/API";

type UseCreateDeliveryInstructionsProps = {
  onSuccess?: () => void;
  onError?: () => void;
};

export const useCreateDeliveryInstructions = ({ onSuccess, onError }: UseCreateDeliveryInstructionsProps = {}) => {
  return useMutation((body: CreateDeliveryInstructionsBody) => createDeliveryInstructions(body), {
    onSuccess,
    onError,
  });
};

type CreateDeliveryInstructionsBody = {
  access: string;
  details: string;
  tracking_number: string;
};

type CreateDeliveryInstructionsResponse = {
  id: string;
  appointment_id: string;
  access: string;
  details: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
};

async function createDeliveryInstructions(body: CreateDeliveryInstructionsBody) {
  const api = new API("logistics");

  const response = await api.post("/delivery_instructions", body);
  return response.data as CreateDeliveryInstructionsResponse;
}
