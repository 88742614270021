import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import { RecoilRoot } from "recoil";

import "./datadog";
import { App } from "./App";
// import * as serviceWorker from "./serviceWorker";
// import notifyNewVersion from "./lib/notifyNewVersion";

import "./lib/i18n";
import "./styles/normalize.css";
import "antd/dist/antd.less";

const queryClient = new QueryClient();

ReactDOM.render(
  <CookiesProvider>
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </RecoilRoot>
  </CookiesProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register({
//   onUpdate: notifyNewVersion,
// });
