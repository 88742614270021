import ChevronDownIcon from "@heroicons/react/solid/ChevronDownIcon";
import styled from "styled-components";

import { COLORS, DEVICE } from "styles";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
  background-color: ${COLORS.WHITE};
  box-shadow: 0px 2px 4px rgb(0 0 0 / 15%);

  &:not(:last-child) {
    margin: 0 0 8px;
  }

  @media ${DEVICE.lg} {
    flex: none;
    width: 360px;
  }
`;

export const ClickableMenuBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;
`;

interface MenuItemIconProps {
  isOpen: boolean;
}

export const MenuItemIcon = styled(({ isOpen, ...props }) => <ChevronDownIcon {...props} />)<MenuItemIconProps>`
  width: 22px;
  color: ${COLORS.WHITE};
  background-color: ${COLORS.BLUE};
  padding: 2px;
  border-radius: 50%;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "")};
  transition: transform 0.25s ease-in-out;
  transition: background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  ${ClickableMenuBar}:hover & {
    background-color: ${COLORS.BLUE_LIGHT};
  }
`;

interface CollapsibleProps {
  isOpen: boolean;
}

export const Collapsible = styled.div<CollapsibleProps>`
  overflow: hidden;
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  z-index: ${({ isOpen }) => (isOpen ? 1 : -1)};
  max-height: ${({ isOpen }) => (isOpen ? "" : "0")};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: all 0.3s, visibility 0s;
`;
