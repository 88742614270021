import styled from "styled-components";

import { COLORS } from "styles";

interface BarProps {
  color: string;
  isFilled: boolean;
}

export const Bar = styled.div<BarProps>`
  display: flex;
  flex: 1;
  border: ${({ isFilled, color }) => `2px solid ${color && isFilled ? color : COLORS.GREY_MID}}`};
  border-radius: 6px;

  &:not(:last-child) {
    margin: 0 8px 0 0;
  }
`;

export const BarLoaderWrapper = styled.div`
  display: flex;
  flex: 1;
  border-radius: 6px;
  overflow: hidden;

  span:first-child {
    display: flex;
    flex: 1;
  }

  &:not(:last-child) {
    margin: 0 8px 0 0;
  }
`;
