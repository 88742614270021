import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

import { Appointment } from "@secondcloset/types/src/fulfillment";

import { Switch } from "antd";

import * as S from "./styles";

import { useUpdateSmsSettings } from "hooks/api/useSmsSettings";

import { Row, Text } from "components/common";

export const SmsToggle: FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const appointment = queryClient.getQueryData<Appointment>("appointment");
  const { mutate, data, isLoading } = useUpdateSmsSettings();

  return (
    <S.Wrapper>
      <Row margin="0 8px 0 0">
        <Text>{t("Want us to text you updates?")}</Text>
      </Row>
      <Switch
        size="small"
        loading={isLoading}
        // defaultChecked
        defaultChecked={appointment?.sms}
        checked={!!data?.sms || !!appointment?.sms}
        checkedChildren={<Row minWidth="20px">Yes</Row>}
        unCheckedChildren={<Row minWidth="20px">No</Row>}
        onChange={(checked) => {
          mutate({ smsSetting: checked });
        }}
      />
    </S.Wrapper>
  );
};
