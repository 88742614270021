import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import dayjs from "dayjs";

import { SCREEN_SIZES } from "styles/screenSizes";
import * as S from "./styles";

import { buildDescriptionWithCity, getCurrentFlowSequence } from "../helpers";

import { StepIcon } from "./StepIcon";

import { TransitFlowStep } from "interface/packageTrackingInfo";

interface TransitTrackingBarProps {
  currentLabelStatus: string;
  transitFlow: TransitFlowStep[];
  deliveredAt?: string;
}

export const TransitTrackingBar: FC<TransitTrackingBarProps> = ({ currentLabelStatus, transitFlow, deliveredAt }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: SCREEN_SIZES.md - 1 });
  const currentStep = getCurrentFlowSequence(currentLabelStatus, transitFlow);

  const getFormattedDeliveredAt = (stepSeq: number, currentStep: number) => {
    const isDeliveredTimeVisible = stepSeq === currentStep && !!deliveredAt && !isMobile;

    if (!isDeliveredTimeVisible) return;
    return (
      <S.DeliveredTime>
        <span>{dayjs(deliveredAt).format("MMM DD")}</span>
        <span>{dayjs(deliveredAt).format("LT")}</span>
      </S.DeliveredTime>
    );
  };

  const renderStepName = (description: string) => {
    const { desc, city } = buildDescriptionWithCity(description);
    return t(desc) + ` ${city}`;
  };

  const renderTransitSteps = () => {
    return transitFlow.map((step) => {
      const isStepCompleted = currentStep >= step.sequence;

      return (
        <S.TransitStep
          key={step.sequence}
          stepNumber={step.sequence}
          icon={<StepIcon stepName={renderStepName(step.description)} isStepCompleted={isStepCompleted} />}
          subTitle={getFormattedDeliveredAt(step.sequence, currentStep)}
        />
      );
    });
  };

  return (
    <S.TransitFlowTracker
      initial={1}
      current={currentStep}
      labelPlacement="vertical"
      direction={isMobile ? "vertical" : "horizontal"}
    >
      {renderTransitSteps()}
    </S.TransitFlowTracker>
  );
};
