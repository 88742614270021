import { useQuery } from "react-query";
import { useHistory } from "react-router";

import { notification } from "antd";

import { useSetRecoilState } from "recoil";
import { apptToken } from "recoil/application/atoms";

import { useGetToken } from "hooks/application/useGetToken";
import { useUrl } from "hooks/application/useUrl";

import { fetchToken, TokenResponse } from "./fetchToken";

export const useFetchToken = () => {
  const dispatchApptToken = useSetRecoilState(apptToken);
  const { getQueryValues } = useUrl();
  const { trackingNumber, ikeaOrderNumber, verify: postalCode } = getQueryValues();
  const token = useGetToken();

  const history = useHistory();

  // maybe there is a better way to handle this, we have 3 flows to access home page, which of them has different format in the url
  // token in the URL, verifying info in URL and from the tracking page
  const hasUrlVerifyingInfo = Boolean(trackingNumber || ikeaOrderNumber || postalCode);
  const isEnabled = !token || hasUrlVerifyingInfo;

  return useQuery<TokenResponse, string>(
    ["token", { postalCode, trackingNumber, ikeaOrderNumber }],
    () => {
      if (!trackingNumber && !ikeaOrderNumber) {
        return Promise.reject("No tracking number or order number provided");
      }
      if (!postalCode) {
        return Promise.reject("No postal code or zip code provided");
      }
      return fetchToken({
        postal_code: postalCode,
        tracking_number: trackingNumber,
        ikea_order_number: ikeaOrderNumber,
      });
    },
    {
      onSuccess: (data) => {
        dispatchApptToken(data.token);
      },
      onError: (e: string) => {
        history.push("/tracking");
        notification.error({
          message: "Error",
          description: e,
        });
      },
      enabled: isEnabled,
      retry: false,
    }
  );
};
