import styled from "styled-components";
import { COLORS, DEVICE } from "styles";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  padding: 48px 16px;
  background: ${COLORS.GREEN_DARK};

  @media ${DEVICE.lg} {
    padding: 72px;
  }
`;
