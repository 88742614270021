import { useRecoilValue } from "recoil";
import { apptToken } from "recoil/application/atoms";

import { useUrl } from "./useUrl";

export const useGetToken = () => {
  const { getQueryValues } = useUrl();
  const storedApptToken = useRecoilValue(apptToken);
  const token = (getQueryValues()?.token as string) || storedApptToken;

  return token;
};
