import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router";

import { useSetRecoilState } from "recoil";
import { packageTrackingNumber } from "recoil/packageTracking/atoms";

import { PackageTrackingInfo } from "interface/packageTrackingInfo";

export const useValidateTrackingNumber = () => {
  const dispatchTrackingNumber = useSetRecoilState(packageTrackingNumber);
  const location = useLocation();
  const urlTrackingNumber = location.pathname.slice(10);

  const history = useHistory();

  const queryClient = useQueryClient();
  const trackingInfo = queryClient.getQueryData<PackageTrackingInfo>("packageTracking");

  const isURLMatchingTrackingInfo = urlTrackingNumber === trackingInfo?.tracking_number;

  useEffect(() => {
    if (!isURLMatchingTrackingInfo) {
      dispatchTrackingNumber(urlTrackingNumber);
    }

    if (!!queryClient.getQueryState("packageTracking")?.error) return history.push("/tracking");
  }, [dispatchTrackingNumber, history, isURLMatchingTrackingInfo, queryClient, urlTrackingNumber]);
};
