import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

import { Appointment } from "@secondcloset/types/src/fulfillment";

import * as S from "./styles";

import { Column, Text } from "components/common";

import { Location } from "interface/location";
import { Route } from "interface/route";

export const ErrorBanner: FC = () => {
  const queryClient = useQueryClient();
  const routeState = queryClient.getQueryState<Route>("route");
  const appointmentState = queryClient.getQueryState<Appointment>("appointment");
  const locationState = queryClient.getQueryState<Location>("location");
  const { t } = useTranslation();

  if (
    !!routeState?.error ||
    !!appointmentState?.error ||
    (!!locationState?.error && locationState?.errorUpdateCount > 40)
  ) {
    return (
      <S.Wrapper>
        <Column flex={0}>
          <S.InfoIcon />
        </Column>
        <Column>
          <Text>{t("We are having some technical difficulties.")}</Text>
          {!!locationState?.error && locationState?.errorUpdateCount > 40 ? (
            <Text>{t("The truck's location may not be accurate.")}</Text>
          ) : null}
        </Column>
      </S.Wrapper>
    );
  }

  return null;
};
