import { useTranslation } from "react-i18next";

import * as S from "./styles";
import { COLORS } from "styles";

type LanguageSwitcherProps = {
  color?: string;
  hoverColor?: string;
};

export const LanguageSwitcher = ({ color = COLORS.BLUE, hoverColor = COLORS.BLUE_LIGHT }: LanguageSwitcherProps) => {
  const { i18n } = useTranslation();

  return (
    <S.LanguageSwitcherWrapper>
      <S.Language
        active={i18n.language === "en"}
        color={color}
        hoverColor={hoverColor}
        onClick={() => {
          i18n.changeLanguage("en");
        }}
      >
        English
      </S.Language>

      <S.Divider>|</S.Divider>

      <S.Language
        active={i18n.language === "fr"}
        color={color}
        hoverColor={hoverColor}
        onClick={() => {
          i18n.changeLanguage("fr");
        }}
      >
        Français
      </S.Language>
    </S.LanguageSwitcherWrapper>
  );
};
