import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { notification } from "antd";

import { updateAddress } from "hooks/api/updateAddress";

import { Address } from "./types";
import { useToken } from "./useToken";

export const useUpdateAddress = () => {
  const { t } = useTranslation();
  const token = useToken();

  return useMutation(
    (address: Address) => {
      if (!token) {
        throw new Error("Token is missing");
      }

      const { address_line_1, address_line_2, city, province, country, postal_code, is_business, coordinates } =
        address;

      if (!coordinates) {
        throw new Error("Coordinates are missing");
      }

      return updateAddress({
        token,
        address: {
          address_line_1,
          address_line_2,
          city,
          province,
          country,
          postal_code,
          is_business,
          latitude: coordinates.lat,
          longitude: coordinates.lng,
        },
      });
    },
    {
      onSuccess: () => {
        notification.success({
          message: t("Delivery address was updated successfully"),
        });
      },
      onError: (error: string) => {
        notification.error({
          message: t("Delivery address could not be updated"),
          description: t(error),
        });
      },
    }
  );
};
