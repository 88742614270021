import { useQuery, UseQueryOptions } from "react-query";

import { API } from "lib/API";

import { PackageTrackingInfo } from "interface/packageTrackingInfo";

const fetchTrackingInfo = async (trackingNumber: string): Promise<PackageTrackingInfo> => {
  const api = new API("logistics");
  const url = `/bolt_shipping_labels/search?tracking_number=${trackingNumber}`;

  const { data } = await api.get(url);
  return data as PackageTrackingInfo;
};

export const usePackageTrackingInfo = (
  trackingNumber: string,
  options?: UseQueryOptions<PackageTrackingInfo, string>
) => {
  return useQuery(
    "packageTracking",
    () => {
      return fetchTrackingInfo(trackingNumber);
    },
    options
  );
};
