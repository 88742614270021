import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { Input, Button, Alert } from "antd";
import { toUpper } from "lodash";

import * as S from "./styles";
import { COLORS } from "styles";
import { SCREEN_SIZES } from "styles/screenSizes";

import backgroundSmall from "assets/tracking-search-page-background-small.png";
import background from "assets/tracking-search-page-background.png";
import { HelpCentreLink } from "components/HelpCentreLink";
import { LanguageSwitcher } from "components/LanguageSwitcher";
import { PageBanner } from "components/PageBanner";

import { useSearchTrackingInfo } from "./useSearchTrackingInfo";

export const TrackingSearchPage: FC = () => {
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState("");
  const isMobile = useMediaQuery({ maxWidth: SCREEN_SIZES.md - 1 });
  const { isError, onClickSearch } = useSearchTrackingInfo();

  const errorMessage = (
    <>
      {t("We didn't find a package associated with this tracking number.")} {t("Having trouble?")} {t("Visit the")}{" "}
      <HelpCentreLink />
    </>
  );

  const renderSearchInput = () => {
    return (
      <S.SearchContainer>
        <Input
          autoFocus
          placeholder={t("Enter Tracking Number")}
          value={searchString}
          suffix={<S.SearchInputIcon />}
          onChange={(e) => setSearchString(toUpper(e.target.value))}
          onPressEnter={() => onClickSearch(searchString)}
          size="large"
        />
        <Button type="primary" style={{ borderRadius: "6px" }} size="large" onClick={() => onClickSearch(searchString)}>
          {t("Track")}
        </Button>
      </S.SearchContainer>
    );
  };

  return (
    <>
      <PageBanner />
      <S.PageContainer>
        <S.ContentContainer>
          <S.Title>{t("PACKAGE TRACKING")}</S.Title>
          <S.Tagline>{t("Track your shipment in real time.")}</S.Tagline>
          {renderSearchInput()}
          {isError && <Alert type="error" showIcon message={errorMessage} style={{ maxWidth: "737.59px" }} />}
        </S.ContentContainer>
        <S.BackgroundImg src={isMobile ? backgroundSmall : background} />

        <S.LanguageSwitcherContainer>
          <LanguageSwitcher color={COLORS.WHITE} hoverColor={COLORS.WHITE} />
        </S.LanguageSwitcherContainer>
      </S.PageContainer>
    </>
  );
};
