import { API } from "lib/API";

type UpdateAddressParams = {
  token: string;
  address: {
    address_line_1: string;
    address_line_2: string;
    postal_code: string;
    city: string;
    province: string;
    country: string;
    latitude: number;
    longitude: number;
    is_business: boolean;
  };
};

export const updateAddress = ({ token, address }: UpdateAddressParams) => {
  const api = new API("galileo", token);

  return api.put("/address", address);
};
