import { FC } from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
// import { startCase, toLower, toUpper } from "lodash";

import * as S from "./styles";

import { buildDescriptionWithCity } from "pages/TrackingInfoPage/helpers";

import { TableMobileCell } from "./TableMobileCell";

import { TransitStep } from "interface/packageTrackingInfo";

interface ShipmentProgressTableProps {
  isMobile: boolean;
  progressEvents: TransitStep[];
}

export const ShipmentProgressTable: FC<ShipmentProgressTableProps> = ({ isMobile, progressEvents }) => {
  const { t } = useTranslation();

  const getMobileColumns = () => {
    return [
      {
        key: "transit_step_row",
        width: "50vw",
        render: (transitStep: TransitStep) => (
          <TableMobileCell
            firstLineData={dayjs(transitStep.created_at).format("MMM DD")}
            // secondLineData={transitStep.location}
          />
        ),
      },
      {
        dataIndex: "description",
        key: "description",
        render: (description: string) => {
          const { desc, city } = buildDescriptionWithCity(description);
          return t(desc) + ` ${city}`;
        },
      },
    ];
  };

  const getTableColumns = () => {
    if (isMobile) return getMobileColumns();
    return [
      {
        title: t("Date"),
        dataIndex: "created_at",
        key: "created_at",
        render: (date: string) => dayjs(date).format("MMM DD"),
      },
      {
        title: t("Time"),
        dataIndex: "created_at",
        key: "created_at",
        render: (time: string) => dayjs(time).format("LT"),
      },
      // {
      //   title: t("Location"),
      //   dataIndex: "location",
      //   key: "location",
      //   render: (location: string) => {
      //     const [city = "", province = "", country = ""] = startCase(toLower(location)).split(" ");
      //     const formattedProvince = province.length > 2 ? province : toUpper(province);

      //     return [city, formattedProvince, country].join(" ").trim().replace(" ", ", ");
      //   },
      // },
      {
        title: t("Progress"),
        dataIndex: "description",
        key: "description",
        render: (description: string) => {
          const { desc, city } = buildDescriptionWithCity(description);
          return t(desc) + ` ${city}`;
        },
      },
    ];
  };

  const updatedTime = progressEvents[0].created_at;

  const renderTableHeader = () => {
    if (isMobile) return;
    return (
      <>
        <S.TableTitle>{t("Shipment Progress")}</S.TableTitle>
        <S.UpdatedTime>
          {t("Information updated")} {dayjs(updatedTime).format("MMM DD, LT")}
        </S.UpdatedTime>
      </>
    );
  };

  return (
    <S.ProgressTable
      rowKey="id"
      dataSource={progressEvents}
      columns={getTableColumns()}
      pagination={false}
      title={() => renderTableHeader()}
    />
  );
};
