import React from "react";
import { useQueryClient } from "react-query";

import * as S from "./styles";

import { useRecoilValue } from "recoil";
import { screenOrientationState } from "recoil/screenOrientation/atoms";

import { DeliveryTime } from "./DeliveryTime";
import { ElectricVehicleCallout } from "./ElectricVehicleCallout/ElectricVehicleCallout";
import { ProgressBar } from "./ProgressBar";
import { Status } from "./Status";

import { Appointment } from "interface/appointment";

export const Header: React.FC = () => {
  const screenOrientation = useRecoilValue(screenOrientationState);
  const queryClient = useQueryClient();
  const appointment = queryClient.getQueryData<Appointment>("appointment");
  const shouldRenderEvCallout = appointment?.is_electric_vehicle;

  return (
    <S.OuterWrapper screenOrientation={screenOrientation}>
      <S.InnerWrapper screenOrientation={screenOrientation}>
        <DeliveryTime />
        <ProgressBar />
        <Status />
        {shouldRenderEvCallout && <ElectricVehicleCallout />}
      </S.InnerWrapper>
    </S.OuterWrapper>
  );
};
