import { MessageCard } from "components/MessageCard";
import { CampaignType } from "../useGetRedirectParams";

type RedirectMessageProps = {
  utmCampaign?: CampaignType;
};

export const RedirectMessage = ({ utmCampaign }: RedirectMessageProps) => {
  const redirectLocation = {
    thumbs_up: "to our Review Page.",
    thumbs_down: "to our Help Center.",
    default: undefined,
  }[utmCampaign || "default"];

  return (
    <MessageCard
      mainMessage={`You are being redirected ${redirectLocation}`}
      subMessage="If you are not redirected in a moment, close this page and try again."
    />
  );
};
