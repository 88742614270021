import { FC, useState } from "react";

import QuestionMarkCircleIcon from "@heroicons/react/outline/QuestionMarkCircleIcon";
import { Button, Modal } from "antd";

import * as S from "./styles";
import { COLORS } from "styles";

import { useUrl } from "hooks/application/useUrl";

import { Row, Text } from "components/common";
import { langaugeSettingCookieName } from "config/cookieName";
import { getLanguageSettingCookie } from "lib/cookies";
import i18n from "lib/i18n";

export const LanguageModal: FC = () => {
  // TODO: display modal if no language from querystring, cookies, localcache, or navigator
  const url = useUrl();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const defaultLanguageModalVisibility =
    !url.getQueryValues()?.lng &&
    !getLanguageSettingCookie() &&
    !localStorage.getItem(langaugeSettingCookieName) &&
    !navigator.language;
  const [isLanguageModalVisible, setIsLanguageModalVisible] = useState(defaultLanguageModalVisibility);

  return (
    <Modal
      centered
      keyboard
      maskClosable
      destroyOnClose
      footer={null}
      visible={isLanguageModalVisible}
      closable={false}
      onCancel={() => {
        setIsLanguageModalVisible(false);
      }}
      style={{ maxWidth: "288px", borderRadius: "6px" }}
      maskStyle={{ backdropFilter: "blur(2px)" }}
    >
      <Row alignItems="center">
        <QuestionMarkCircleIcon width={18} color={COLORS.BLUE} />
        <Text margin="0 0 0 8px">What's your preferred language?</Text>
      </Row>
      <Row margin="16px 0 0" justifyContent="flex-end">
        <S.ButtonWrapper>
          <Button
            size="small"
            onClick={() => {
              i18n.changeLanguage("en");
              setIsLanguageModalVisible(false);
            }}
          >
            English
          </Button>
        </S.ButtonWrapper>
        <S.ButtonWrapper margin="0 0 0 8px">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              i18n.changeLanguage("fr");
              setIsLanguageModalVisible(false);
            }}
          >
            Français
          </Button>
        </S.ButtonWrapper>
      </Row>
    </Modal>
  );
};
