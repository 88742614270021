import { FC } from "react";
import { useQueryClient } from "react-query";
import { useMediaQuery } from "react-responsive";
import BarLoader from "react-spinners/BarLoader";

import { Appointment } from "@secondcloset/types/src/fulfillment";

import * as S from "./styles";
import { COLORS } from "styles";
import { SCREEN_SIZES } from "styles/screenSizes";

import { useRecoilValue } from "recoil";
import { screenOrientationState } from "recoil/screenOrientation/atoms";

import { useFetchToken } from "hooks/api/useFetchToken";
import { useInitialize } from "hooks/application/useInitialize";

import { CompanyLogos } from "components/CompanyLogos";
import { ErrorBanner } from "components/ErrorBanner";

import { Header } from "./Header";
import { Map } from "./Map";
import { Menu } from "./Menu";

export const HomePage: FC = () => {
  useFetchToken();

  const isMobile = useMediaQuery({ maxWidth: SCREEN_SIZES.lg - 1 });
  const screenOrientation = useRecoilValue(screenOrientationState);
  const queryClient = useQueryClient();
  const appointment = queryClient.getQueryData<Appointment>("appointment");

  useInitialize();

  return (
    <S.Container>
      <S.MapWrapper>
        {isMobile && screenOrientation === "portrait" ? <Header /> : null}
        <ErrorBanner />
        {!appointment ? (
          <S.MapSpinner>
            <BarLoader color={COLORS.BLUE} loading width={100} height={3} />
          </S.MapSpinner>
        ) : (
          <Map />
        )}
        {isMobile ? <CompanyLogos /> : null}
      </S.MapWrapper>
      <Menu />
    </S.Container>
  );
};
