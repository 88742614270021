import styled from "styled-components";
import { COLORS } from "styles";
import { SCREEN_SIZES } from "styles/screenSizes";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 30%;

  @media (max-width: ${SCREEN_SIZES.md - 1}px) {
    flex-direction: row;
    max-width: 100%;
    margin-left: 6px;
  }
`;

export const StepName = styled.div`
  margin-top: 14px;
  font-size: 24px;
  color: ${COLORS.BLACK};
  weight: 600;
  line-height: 29px;
  white-space: nowrap;
  text-align: left;

  @media (max-width: ${SCREEN_SIZES.md - 1}px) {
    margin: 0 0 0 9px;
    font-size: 16px;
    weight: 500;
    line-height: 24px;
  }

  @media (max-width: ${SCREEN_SIZES.xl - 1}px) {
    white-space: normal;
  }
`;
