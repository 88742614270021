import { FC } from "react";
import { useQueryClient } from "react-query";

import { Appointment } from "@secondcloset/types/src/fulfillment";

import * as S from "./styles";

import boltLogo from "assets/bolt-logo.png";

export const CompanyLogos: FC = () => {
  const queryClient = useQueryClient();
  const appointment = queryClient.getQueryData<Appointment>("appointment");

  return (
    <S.Wrapper>
      {appointment?.shipment?.organization?.logo?.url ? (
        <>
          <S.Logo
            src={appointment?.shipment?.organization?.logo?.url}
            alt={`${appointment?.shipment?.organization?.name} Logo`}
          />
          <S.Separator />
        </>
      ) : null}
      <S.Logo
        src={boltLogo}
        alt="Bolt Logo"
        cursor="pointer"
        onClick={() => {
          window.open("https://gobolt.com", "_blank");
        }}
      />
    </S.Wrapper>
  );
};
