import { FC } from "react";
import { Space } from "antd";
import { ReactComponent as GoBoltLogo } from "assets/gobolt-logo-monotone-lightondark.svg";
import * as S from "./styles";
import { MessageCard } from "components/MessageCard";

export const FeedbackAppreciatedPage: FC = () => {
  const subMessage =
    "Please look out for a review email from your merchant. We would be grateful if you took a moment and shared your GoBolt delivery experience with them.";
  return (
    <S.Container>
      <Space direction="vertical" align="center" size={40}>
        <GoBoltLogo width={150} />
        <MessageCard mainMessage="Thank you for your feedback!" subMessage={subMessage} />
      </Space>
    </S.Container>
  );
};
