import styled from "styled-components";
import { COLORS } from "styles";
import { SCREEN_SIZES } from "styles/screenSizes";
import { Button } from "antd";

export const PageContainer = styled.div`
  color: ${COLORS.ALT_BACKGROUND};
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 35px 16px;
  height: 95vh;
  overflow-y: scroll;

  @media (min-width: ${SCREEN_SIZES.lg - 1}px) {
    padding: 70px 130px;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h3`
  margin: 0;
  font-size: 20px;
  @media (min-width: ${SCREEN_SIZES.md}px) {
    font-size: 32px;
  }
`;

export const TrackAnotherPackageButton = styled(Button)`
  height: 40px;
  border: 1px solid ${COLORS.GREY_DARK};
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${SCREEN_SIZES.sm - 1}px) {
    flex-direction: column;
  }
`;

export const UpdateAddressButton = styled(Button)`
  border: 1px solid ${COLORS.GREY_DARK};
`;

export const LiveTrackForm = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  max-width: 350px;
`;

export const FooterContainer = styled.div`
  margin-top: 16px;
  padding: 16px 0 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${COLORS.GREY_DISABLED};
`;

export const FooterTitle = styled.h1`
  margin: 0;
  font-size: 24px;
`;

export const FooterText = styled.h3`
  margin: 0;
  font-size: 16px;
`;

type RowProps = {
  isMobile: boolean;
};

export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  flex-wrap: wrap;
  justify-content: ${(props) => (props.isMobile ? "flex-start" : "space-between")};
  gap: 16px;
`;
