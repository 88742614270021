import { Appointment as IAppointment } from "@secondcloset/types/src/fulfillment";

import { toLower } from "lodash";
import startCase from "lodash/startCase";
import toUpper from "lodash/toUpper";

import { provinces } from "../constants";

export class AppointmentUtils {
  static getFormattedAddress = (appointment?: IAppointment): string | undefined => {
    if (!appointment) return undefined;

    const address = toLower(appointment?.location?.address).replace(/\w+/g, startCase);
    const city = toLower(appointment?.location?.city).replace(/\w+/g, startCase);
    const province = toUpper(
      appointment?.location && appointment?.location?.province?.length === 2
        ? toLower(appointment?.location?.province).replace(/\w+/g, startCase)
        : provinces[appointment?.location?.province || ""]
    );
    const postalCode = toUpper(appointment?.location?.postal_code);
    return `${address}, ${city}, ${province}, ${postalCode}`;
  };

  static getDeliveryMethod = ({
    appointment,
    t,
  }: {
    appointment?: IAppointment;
    t: (text: string) => string;
  }): string | undefined => {
    if (!appointment) return undefined;

    const deliveryServiceMethods = {
      standard: t("Threshold"),
      white_glove: t("White Glove"),
      to_the_door: t("To The Door"),
      room_of_choice: t("Room(s) of Choice"),
      standard_1_person: t("Threshold"),
      room_of_choice_1_person: t("Room(s) of Choice"),
      to_the_door_1_person: t("To The Door"),
      second_closet_standard: t("Threshold"),
      second_closet_white_glove: t("White Glove"),
      second_closet_to_the_door: t("To The Door"),
      second_closet_room_of_choice: t("Room(s) of Choice"),
      second_closet_standard_1_person: t("Threshold"),
      second_closet_room_of_choice_1_person: t("Room(s) of Choice"),
      second_closet_to_the_door_1_person: t("To The Door"),
    };
    if (appointment?.delivery_service_level) {
      return deliveryServiceMethods[appointment?.delivery_service_level];
    } else {
      return undefined;
    }
  };

  static checkIsReloAppointment = (appointment?: IAppointment) => {
    const jobType = appointment?.job_type;
    if (jobType === "reverse_logistics") return true;
    return false;
  };
}
