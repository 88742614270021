const credentialCookieName = "delivery-tracker-credential";
const appSettingsCookieName = "delivery-tracker-app-settings";
const routePolylineCookieName = "delivery-tracker-route-polyline";
const estimatedTravelTimeCookieName = "delivery-tracker-estimated-travel-time";
const langaugeSettingCookieName = "delivery-tracker-language-setting";
const storedAppointmentTokenCookieName = "delivery-tracker-stored-appointment-token";

export {
  appSettingsCookieName,
  credentialCookieName,
  routePolylineCookieName,
  estimatedTravelTimeCookieName,
  langaugeSettingCookieName,
  storedAppointmentTokenCookieName,
};
