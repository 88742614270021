import React from "react";
import { useTranslation } from "react-i18next";

import { Tooltip as AntdTooltip, notification } from "antd";

import * as S from "./styles";

import { useRequestAddressUpdate } from "hooks/api/useRequestAddressUpdate";

import { LABEL_DELIVERED, LABEL_OUT_FOR_DELIVERY } from "constants/labelStatuses";
import { PackageTrackingInfo } from "interface/packageTrackingInfo";

type UpdateAddressButtonProps = {
  trackingInfo: PackageTrackingInfo;
};

export const UpdateAddressButton = ({ trackingInfo }: UpdateAddressButtonProps) => {
  const { t } = useTranslation();
  const requestAddressUpdate = useRequestAddressUpdate();

  const isDisabled =
    trackingInfo.label_status === LABEL_OUT_FOR_DELIVERY || trackingInfo.label_status === LABEL_DELIVERED;

  return (
    <Tooltip enabled={isDisabled}>
      <S.UpdateAddressButton
        loading={requestAddressUpdate.isLoading}
        disabled={isDisabled}
        onClick={async () => {
          await requestAddressUpdate.mutateAsync({
            tracking_number: trackingInfo.tracking_number,
          });
          notification.success({
            message: t("Check your email for a link to update your address"),
            duration: 0,
          });
        }}
      >
        {t("Update address")}
      </S.UpdateAddressButton>
    </Tooltip>
  );
};

function Tooltip({ enabled, children }: { enabled: boolean; children: React.ReactNode }) {
  const { t } = useTranslation();

  if (enabled) {
    return (
      <AntdTooltip title={t("This appointment is already out for delivery, no changes can be made")}>
        {children}
      </AntdTooltip>
    );
  }

  return <>{children}</>;
}
