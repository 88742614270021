import type { Appointment } from "hooks/api/fetchAppointment";

import type { Address } from "./types";

export const getAppointmentAddressText = (address: Address) => {
  return [address.address_line_1, address.address_line_2, address.city, address.province, address.postal_code]
    .filter(Boolean)
    .join(", ");
};

export const getShouldDisableEditingAppointmentAddress = ({ status }: Appointment) => {
  return (
    status === "loaded" ||
    status === "cancelled" ||
    status === "fulfilled" ||
    status === "on_the_way" ||
    status === "active" ||
    status === "done" ||
    status === "completed" ||
    status === "completed_with_exceptions" ||
    status === "started" ||
    status === "arrived" ||
    status === "incomplete" ||
    status === "failed"
  );
};
