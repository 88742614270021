import { Space } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { GOBOLT_SUPPORT_EMAIL } from "constants/global";
import { LABEL_DELIVERED, LABEL_OUT_FOR_DELIVERY } from "constants/labelStatuses";
import { PackageTrackingInfo } from "interface/packageTrackingInfo";

import { buildReadableDate } from "../helpers";
import * as S from "./styles";

interface EstimatedDeliveryProps {
  trackingInfo: PackageTrackingInfo;
}

export const EstimatedDeliveryInfo: FC<EstimatedDeliveryProps> = ({ trackingInfo }) => {
  const { t } = useTranslation();
  const { estimated_delivery_date, label_status } = trackingInfo;

  const shouldHideDate =
    !estimated_delivery_date || label_status === LABEL_OUT_FOR_DELIVERY || label_status === LABEL_DELIVERED;

  if (shouldHideDate) return null;

  const dateStr = new Date(estimated_delivery_date);
  const isDelayedDelivery = new Date(estimated_delivery_date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);

  return (
    <S.Container>
      <Space direction="vertical" align="start" size={40}>
        <S.MainText>
          {isDelayedDelivery ? (
            <>
              <S.FooterText>
                {t("If you have any questions regarding the status of your delivery, you can contact us at")}{" "}
                <a href={`mailto:${GOBOLT_SUPPORT_EMAIL}`}>{GOBOLT_SUPPORT_EMAIL}</a>
              </S.FooterText>
            </>
          ) : (
            `Estimated Delivery Date: ${buildReadableDate(dateStr)}`
          )}
        </S.MainText>
      </Space>
    </S.Container>
  );
};
