import { COLORS, DEVICE } from "styles";
import styled from "styled-components";

export const ElectricVehicleIconWrapper = styled.div`
    display: flex;
    width: 95px;
    height 100%;
    justify-content: center;
    border-radius: 6px;
    background: ${COLORS.GREEN_DARK};
`;

export const ElectricVehicleIcon = styled.img`
  height: auto;
  max-height: 90%;
  width: auto;
  max-width: 148px;
  margin: 4px 8px 4px 8px;
`;

export const Divider = styled.div`
  width: 97%;
  height: 0px;
  border-top: 1px solid ${COLORS.GREY_LIGHT};
  margin: 16px 0 0 0;
  position: absolute;
  left: 8px;

  @media ${DEVICE.lg} {
    left: 0px;
    width: 100%;
  }
`;
