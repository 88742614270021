import { useLocation } from "react-router";

import queryString from "query-string";

export type CampaignType = "thumbs_up" | "thumbs_down";

interface QueryValues {
  token?: string;
  utm_campaign?: CampaignType;
  utm_source?: string;
  email?: string;
  locale?: string;
}

export const useGetRedirectParams = () => {
  const location = useLocation();

  return queryString.parse(location.search) as QueryValues;
};
