import styled from "styled-components";

import { SCREEN_SIZES } from "styles/screenSizes";
import { COLORS } from "styles";

export const Container = styled.div`
  background: ${COLORS.WHITE};
  border-radius: 6px;
  padding: 24px 30px;
  @media (max-width: ${SCREEN_SIZES.md - 1}px) {
    padding: 10px 16px;
  }
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  height: fit-content;
`;
