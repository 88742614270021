import { FC } from "react";
import { useMediaQuery } from "react-responsive";

import * as S from "./styles";
import { COLORS } from "styles";
import { SCREEN_SIZES } from "styles/screenSizes";

interface StepIconProps {
  stepName: string;
  isStepCompleted?: boolean;
}

export const StepIcon: FC<StepIconProps> = ({ stepName, isStepCompleted }) => {
  const isMobile = useMediaQuery({ maxWidth: SCREEN_SIZES.md - 1 });
  const width = isMobile ? "24" : "41";

  return (
    <S.Container>
      <svg width={width} height={width} viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20.5" cy="20.5" r="20.5" fill={isStepCompleted ? COLORS.BLUE : COLORS.GREY_DISABLED} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.7071 15.2929C29.0976 15.6834 29.0976 16.3166 28.7071 16.7071L18.7071 26.7071C18.3166 27.0976 17.6834 27.0976 17.2929 26.7071L13.2929 22.7071C12.9024 22.3166 12.9024 21.6834 13.2929 21.2929C13.6834 20.9024 14.3166 20.9024 14.7071 21.2929L18 24.5858L27.2929 15.2929C27.6834 14.9024 28.3166 14.9024 28.7071 15.2929Z"
          fill="white"
        />
      </svg>
      <S.StepName>{stepName}</S.StepName>
    </S.Container>
  );
};
