import { datadogRum } from "@datadog/browser-rum";

import packageInfo from "../package.json";

const ACTIVE_DATADOG_ENVIRONMENTS = ["preprod", "production"];
const env = process.env.REACT_APP_ENV;

const enableDatadogMonitoring = () => {
  const applicationId = process.env.REACT_APP_DATADOG_APPLICATION_ID;
  const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;

  function assertEnv(name: string, value: string | undefined): asserts value is string {
    if (!value) throw new Error(`Environment variable ${name} is not defined`);
  }

  assertEnv("DATADOG_APPLICATION_ID", applicationId);
  assertEnv("DATADOG_CLIENT_TOKEN", clientToken);

  datadogRum.init({
    applicationId,
    clientToken,
    service: "galileo",
    // TODO: update this to datadoghq.com after moving to production account
    site: "datadoghq.com",
    env,
    version: packageInfo.version,
    sampleRate: 100,
    sessionReplaySampleRate: 20,
    trackInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow",
    allowedTracingOrigins: [/https:\/\/.*(gobolt|secondcloset)\.com/],
  });

  datadogRum.startSessionReplayRecording();
};

if (process.env.REACT_APP_DATADOG_ENABLED === "true" && env && ACTIVE_DATADOG_ENVIRONMENTS.includes(env)) {
  enableDatadogMonitoring();
}
