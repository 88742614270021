import { FC } from "react";
import { useTranslation } from "react-i18next";

import * as S from "./styles";

interface TableMobileDrawerProps {
  visible: boolean;
  onClose: () => void;
  table: React.ReactNode;
}

export const TableMobileDrawer: FC<TableMobileDrawerProps> = ({ visible, onClose, table }) => {
  const { t } = useTranslation();

  return (
    <S.StyledDrawer
      title={t("Shipment Progress")}
      visible={visible}
      onClose={onClose}
      placement="bottom"
      closeIcon={<S.DrawerCloseIcon />}
      height={"60vh"}
    >
      {table}
    </S.StyledDrawer>
  );
};
