import { FC, useEffect } from "react";

import { Space } from "antd";
import { useRedirectUrl } from "./useRedirectUrl";
import * as S from "./styles";
import { CampaignType, useGetRedirectParams } from "./useGetRedirectParams";
import { RedirectMessage } from "./RedirectMessage";
import { ReactComponent as GoBoltLogo } from "assets/gobolt-logo-monotone-lightondark.svg";
import ReactGA from "react-ga4";

const GA4_MEASUREMENT_ID = process.env.REACT_APP_GA4_REDIRECT_MEASUREMENT_ID || "G-V6L6TZ6K09";

export const RedirectPage: FC = () => {
  const { utm_campaign, utm_source, email } = useGetRedirectParams();
  const redirectUrl = useRedirectUrl();

  useEffect(() => {
    if (!!redirectUrl) {
      sendGAEventAndRedirect({ redirectUrl, utm_campaign, utm_source, email });
    }
  }, [redirectUrl]);

  return (
    <S.Container>
      <Space direction="vertical" align="center" size={40}>
        <GoBoltLogo width={150} />
        <RedirectMessage utmCampaign={utm_campaign} />
      </Space>
    </S.Container>
  );
};

type SendGAEventAndRedirectProps = {
  redirectUrl: string;
  utm_campaign?: CampaignType;
  utm_source?: string;
  email?: string;
};

function sendGAEventAndRedirect({ redirectUrl, utm_campaign, utm_source, email }: SendGAEventAndRedirectProps) {
  const eventLabel = { thumbs_up: "Positive", thumbs_down: "Negative", default: "N/A" }[utm_campaign || "default"];

  ReactGA.initialize(GA4_MEASUREMENT_ID, {
    gtagOptions: { debug: process.env.NODE_ENV === "development", hitCallback: redirectPage(redirectUrl) },
    gaOptions: { debug_mode: process.env.NODE_ENV === "development" },
  });
  ReactGA.set({ page: window.location.pathname });
  ReactGA.event("review_link_click", {
    label: eventLabel,
    category: "Redirect",
    organization: utm_source || "N/A",
    action: `Redirected to ${redirectUrl}`,
    email: email,
  });
}

function redirectPage(redirectUrl: string) {
  window.location.replace(redirectUrl);
}
