import styled from "styled-components";

interface TextProps {
  margin?: string;
  color?: string;
  fontSize?: number;
  fontWeight?: number;
  textDecoration?: string;
  lineHeight?: number;
  overflowWrap?: string;
}

export const Text = styled.span<TextProps>`
  margin: ${({ margin }) => (margin ? margin : "")};
  color: ${({ color }) => (color ? color : "")};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "")};
  text-decoration: ${({ textDecoration }) => (textDecoration ? textDecoration : "")};
  line-height: ${({ lineHeight }) => (lineHeight ? `${lineHeight}px` : "normal")};
  overflow-wrap: ${({ overflowWrap }) => (overflowWrap ? overflowWrap : "")};
`;
