import styled from "styled-components";
import { DEVICE } from "styles";

export const Container = styled.div`
  margin-bottom: 24px;
  align-self: flex-start;
`;

export const MainText = styled.h2`
  font-size: 22px;
  margin-bottom: 0;

  @media ${DEVICE.lg} {
    font-size: 26px;
  }
`;

export const FooterText = styled.div`
  margin-top: 16px;
  color: #222222;
  font-size: 20px;
  line-height: 24px;
`;