import Api, { APIDomain } from "@secondcloset/api-utils";

import queryString from "query-string";

interface HeaderTemplateProps {
  headers: {
    Accept: string;
    "Content-Type": string;
    Authorization?: string;
  };
}

export class API extends Api {
  constructor(domain?: APIDomain, token?: string) {
    const getBaseUrl = (domain?: string): string => {
      if (domain === "pigeon") {
        return process.env.REACT_APP_PIGEON_API as string;
      }
      return process.env.REACT_APP_API as string;
    };

    super({
      baseURL: getBaseUrl(domain),
      domain,
      token: token || (queryString.parse(window.location.search)?.token as string),
    });
  }

  getHeaderTemplate(token: string) {
    const header: HeaderTemplateProps = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    if (token) header.headers.Authorization = "Bearer ".concat(token);
    return header;
  }

  get(url: string) {
    return super.get(url).catch((error) => {
      throw error;
    });
  }

  delete(url: string) {
    return super.delete(url).catch((error) => {
      throw error;
    });
  }

  put(url: string, body?: any) {
    return super.put(url, body).catch((error) => {
      throw error;
    });
  }

  post(url: string, body?: any) {
    return super.post(url, body).catch((error) => {
      throw error;
    });
  }
}
