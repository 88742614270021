import { FC } from "react";

import { Space, Typography } from "antd";

import { StyledErrorBoundaryFallback } from "components/ErrorBoundaryFallback/styles";

export const ErrorPage: FC = () => {
  return (
    <StyledErrorBoundaryFallback
      status="500"
      title="Oops! Something isn't right here."
      subTitle={
        <Space direction="vertical">
          <Typography.Text type="secondary" strong>
            Our team has been notified. Please click the tracking link in your email to view the status of your
            delivery.
          </Typography.Text>
        </Space>
      }
    />
  );
};
