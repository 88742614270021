import { useQuery } from "react-query";

import { useGetToken } from "hooks/application/useGetToken";

import { fetchAppointment } from "./fetchAppointment";

export const useAppointment = () => {
  const token = useGetToken();

  return useQuery(
    "appointment",
    () => {
      return fetchAppointment(token);
    },
    {
      enabled: !!token,
      refetchInterval: 60000, // 1 min
    }
  );
};
