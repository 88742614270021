import { createGlobalStyle } from "styled-components";

import { COLORS } from "./colors";
import { SCREEN_SIZES } from "./screenSizes";

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

  html {
    position: fixed;
    overflow: hidden;
  }

  body {
    position: fixed;
    overflow: hidden;
    margin: 0;
    padding: 0;
    background-color: ${COLORS.APP_BACKGROUND};
    font-family: Inter, sans-serif;
  }

  #root {
    width: 100vw;
    height: 100%;
    -webkit-overflow-scrolling: touch;

    @media (max-width: ${SCREEN_SIZES.lg - 1}px) {
      overflow-y: scroll;
    }
  }
`;
