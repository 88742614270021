import { Cookies as ReactCookie } from "react-cookie";

import { Common } from "@secondcloset/types";

import dayjs from "dayjs";

import {
  credentialCookieName,
  appSettingsCookieName,
  routePolylineCookieName,
  estimatedTravelTimeCookieName,
  langaugeSettingCookieName,
  storedAppointmentTokenCookieName,
} from "config/cookieName";

// Interfaces

type Credential = Common.Credential;

type LanguageSetting = "en" | "fr";

interface AppSettings {
  darkMode?: boolean;
}

interface RoutePolylineCookie {
  routePolyline: string;
  updatedAt: number;
}

interface EstimatedTravelTimeCookie {
  estimatedTravelTime: number;
  updatedAt: number;
}

export const getCredential = (): Credential | undefined => {
  const cookie = new ReactCookie();
  return cookie.get(credentialCookieName);
};

const setCookie = ({
  name,
  data,
  expires,
  maxAge,
}: {
  name: string;
  data: Credential | AppSettings | RoutePolylineCookie | EstimatedTravelTimeCookie | string;
  expires?: Date;
  maxAge?: number;
}) => {
  const cookie = new ReactCookie();
  cookie.set(name, data, { expires, maxAge });
};

export const setCredential = (credential: Credential, rememberMe = false) => {
  const days = 1;
  const expires = new Date();
  expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * days);
  setCookie({
    name: credentialCookieName,
    data: { ...credential, rememberMe },
    expires,
  });
};

export const removeCredential = () => {
  const cookie = new ReactCookie();
  cookie.remove(credentialCookieName);
};

export const getUserToken = (): string => {
  try {
    const cookie = new ReactCookie();
    const credential = cookie.get(credentialCookieName);
    return credential.token.token;
  } catch (_) {
    return "";
  }
};

export const getUserAppSettingsCookieName = (): string | undefined => {
  const userToken = getUserToken();
  if (!userToken) return;
  return `${userToken}-${appSettingsCookieName}`;
};

export const setAppSettings = (appSettings: AppSettings) => {
  const cookieName = getUserAppSettingsCookieName();
  if (!cookieName) return;
  const days = 1;
  const expires = new Date();
  expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * days);
  setCookie({ name: cookieName, data: appSettings, expires });
};

export const getAppSettings = (): AppSettings | undefined => {
  const cookieName = getUserAppSettingsCookieName();
  if (!cookieName) return;
  const cookie = new ReactCookie();
  return cookie.get(cookieName) || {};
};

export const getRoutePolylineCookie = (appointmentId: string): RoutePolylineCookie | undefined => {
  const cookie = new ReactCookie();
  return cookie.get(`${routePolylineCookieName}_${appointmentId}`);
};

export const setRoutePolylineCookie = (routePolyline: string, appointmentId: string) => {
  setCookie({
    name: `${routePolylineCookieName}_${appointmentId}`,
    data: { routePolyline, updatedAt: Date.now() },
    maxAge: 1200,
  });
};

export const getEstimatedTravelTimeCookie = (appointmentId: string): EstimatedTravelTimeCookie | undefined => {
  const cookie = new ReactCookie();
  return cookie.get(`${estimatedTravelTimeCookieName}_${appointmentId}`);
};

export const setEstimatedTravelTimeCookie = (estimatedTravelTime: number, appointmentId: string) => {
  setCookie({
    name: `${estimatedTravelTimeCookieName}_${appointmentId}`,
    data: { estimatedTravelTime, updatedAt: Date.now() },
    maxAge: 1200,
  });
};

export const getLanguageSettingCookie = (): LanguageSetting | undefined => {
  const cookie = new ReactCookie();
  return cookie.get(langaugeSettingCookieName);
};

export const setApptToken = (apptToken: string) => {
  const expires = dayjs().add(1, "day").toDate();
  setCookie({ name: storedAppointmentTokenCookieName, data: JSON.stringify(apptToken), expires });
};

export const getApptToken = () => {
  const cookie = new ReactCookie();
  return cookie.get(storedAppointmentTokenCookieName);
};

export const removeApptToken = () => {
  const cookie = new ReactCookie();
  cookie.remove(storedAppointmentTokenCookieName);
};
