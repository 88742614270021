import { useState } from "react";
import { useTranslation } from "react-i18next";

import { AutoComplete } from "antd";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import * as S from "./styles";

import { AddressMap } from "../AddressMap";
import { GOOGLE_API_KEY } from "../env";
import { getAppointmentAddressText } from "../helpers";
import { Address } from "../types";

const RESTRICT_SEARCH_BOUNDS = {
  west: -168.519536,
  east: -52.240241,
  north: 74.550261,
  south: 22.374134,
};

type AddressFormProps = {
  address: Address;
  loading?: boolean;
  disabled?: boolean;
  onSubmit: (address: Address) => void;
};

export const AddressForm = ({
  address: initialAddress,
  loading = false,
  disabled = false,
  onSubmit,
}: AddressFormProps) => {
  const { t } = useTranslation();
  const [address, setAddress] = useState(initialAddress);
  const [addressInput, setAddressInput] = useState("");

  const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: GOOGLE_API_KEY,
    options: {
      bounds: RESTRICT_SEARCH_BOUNDS,
    },
  });

  const options = placePredictions.map((place) => ({
    value: place.place_id,
    label: place.description,
  }));

  return (
    <S.Container>
      <S.GlobalStyles />

      <S.Label>
        {t("Address")}
        <AutoComplete
          value={addressInput}
          onChange={setAddressInput}
          options={options}
          disabled={disabled}
          dropdownClassName="address-form__autocomplete-dropdown"
          onSearch={(value) => {
            getPlacePredictions({ input: value });
          }}
          onSelect={(placeId: string, { label }: { label: string }) => {
            setAddressInput(label);

            placesService.getDetails({ placeId }, (place: GooglePlace) => {
              setAddress({
                ...createAddressFromGooglePlace(place),
                coordinates: {
                  lat: place.geometry.location.lat(),
                  lng: place.geometry.location.lng(),
                },
                is_business: false,
              });
            });
          }}
        >
          <S.Input placeholder={t("Search for an address...")} />
        </AutoComplete>
      </S.Label>

      <S.Label>
        {t("Apt / Suite # (optional)")}
        <S.Input
          disabled={disabled}
          placeholder={t("Enter your apt or suite #")}
          onChange={(e) => {
            setAddress((prev) => ({
              ...prev,
              address_line_2: e.target.value,
            }));
          }}
        />
      </S.Label>

      <S.AddressContainer>
        <S.AddressText>{getAppointmentAddressText(address)}</S.AddressText>

        <S.AddressMapContainer>
          <AddressMap location={address.coordinates} />
        </S.AddressMapContainer>

        <S.BusinessAddressCheckbox
          checked={address.is_business}
          disabled={disabled}
          onChange={(e) => {
            setAddress((prev) => ({
              ...prev,
              is_business: e.target.checked,
            }));
          }}
        >
          {t("This is a business address")}
        </S.BusinessAddressCheckbox>
      </S.AddressContainer>

      <S.UpdateAddressButton type="primary" loading={loading} disabled={disabled} onClick={() => onSubmit(address)}>
        {t("Update address")}
      </S.UpdateAddressButton>
    </S.Container>
  );
};

type GooglePlace = {
  address_components: GooglePlaceAddressComponent[];
  geometry: {
    location: {
      lat: () => number;
      lng: () => number;
    };
  };
};

type GooglePlaceAddressComponent = {
  long_name: string;
  short_name: string;
  types: GooglePlaceAddressComponentType[];
};

type GooglePlaceAddressComponentType =
  | "country"
  | "locality"
  | "postal_town"
  | "administrative_area_level_1"
  | "postal_code"
  | "street_number"
  | "route"
  | "subpremise";

function createAddressFromGooglePlace(place: GooglePlace) {
  const { country, locality, postal_town, administrative_area_level_1, postal_code, street_number, route, subpremise } =
    getPlaceDetailsDict(place);

  return {
    country: country,
    city: locality ?? postal_town,
    province: administrative_area_level_1,
    postal_code: postal_code,
    address_line_1: getAddressString(street_number, route),
    address_line_2: subpremise,
  };
}

function getAddressString(streetNumber: string, route: string) {
  return [streetNumber, route].filter(Boolean).join(" ");
}

function getPlaceDetailsDict(details: GooglePlace) {
  return details.address_components.reduce((acc, component) => {
    acc[component.types[0]] = component.long_name;
    return acc;
  }, {} as Record<GooglePlaceAddressComponentType, string>);
}
