interface Colors {
  APPLE_BLUE: "#007AFF";
  BLACK: "#000000";
  BLUE: "#384DF6";
  BLUE_LIGHT: "#6075FF";
  CHARCOAL: "#404041";
  ORANGE: "#F5A623";
  GOLD: "#FFBD5D";
  GOLD_DARK: "#B27330";
  GREEN: "#238D87";
  GREEN_LIGHT: "#4EA39F";
  GREEN_DARK: "#00282B";
  GREEN_PRIMARY: "#30606B";
  GREY: "#9B9B9B";
  GREY_LIGHT: "#EEEEEE";
  GREY_MID: "#D5D5D5";
  GREY_DARK: "#7D7D7D";
  GREY_DISABLED: "#C1C1C1";
  RED: "#FF3A52";
  YELLOW: "#FFD33D";
  YELLOW_LIGHT: "#FFECAD";
  WHITE: "#FFFFFF";
  TRANSPARENT: "transparent";
  TRANSPARENT_GREY: "rgba(0,0,0,0.25)";
  APP_BACKGROUND: "#FAFAFA";
  ALT_BACKGROUND: "#E5E5E5";
  SURFACE_BORDER: "#CBCBCB";
  ONSURFACE_COPY: "#3E3E3E";
  [index: string]: string;
}

export const COLORS: Colors = {
  APPLE_BLUE: "#007AFF",
  BLACK: "#000000",
  BLUE: "#384DF6",
  BLUE_LIGHT: "#6075FF",
  CHARCOAL: "#404041",
  ORANGE: "#F5A623",
  GOLD: "#FFBD5D",
  GOLD_DARK: "#B27330",
  GREEN: "#238D87",
  GREEN_LIGHT: "#4EA39F",
  GREEN_DARK: "#00282B",
  GREEN_PRIMARY: "#30606B",
  GREY: "#9B9B9B",
  GREY_LIGHT: "#EEEEEE",
  GREY_MID: "#D5D5D5",
  GREY_DARK: "#7D7D7D",
  GREY_DISABLED: "#C1C1C1",
  RED: "#FF3A52",
  YELLOW: "#FFD33D",
  YELLOW_LIGHT: "#FFECAD",
  WHITE: "#FFFFFF",
  TRANSPARENT: "transparent",
  TRANSPARENT_GREY: "rgba(0,0,0,0.25)",
  APP_BACKGROUND: "#FAFAFA",
  ALT_BACKGROUND: "#E5E5E5",
  SURFACE_BORDER: "#CBCBCB",
  ONSURFACE_COPY: "#3E3E3E",
};
