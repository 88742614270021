import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

import { GlobalStyle } from "./styles/globalStyle";

import { LanguageModal } from "./components/LanguageModal/LanguageModal";
import { useCheckForUpdate } from "./hooks/application/useCheckForUpdate";
import { useScreenOrientation } from "./hooks/application/useScreenOrientation";
import { Router } from "./router";

const queryClient = new QueryClient();

export const App = () => {
  useCheckForUpdate();
  useScreenOrientation();

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <GlobalStyle />
        <Router />
        <LanguageModal />
      </BrowserRouter>
    </QueryClientProvider>
  );
};
