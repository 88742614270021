import { AppointmentType, AppointmentStatus } from "@secondcloset/types/src/fulfillment";

import { API } from "lib/API";

export type Appointment = {
  id: string;
  status: AppointmentStatus;
  job_type: AppointmentType;
  source: {
    customer: {
      name: string;
      email_address: string;
      phone_number: string;
    };
  };
  shipment: {
    external_order: {
      external_order_number: string;
    };
    shipment_number: string;
  };
  location: {
    address: string;
    address_line_1: string;
    address_line_2: string;
    city: string;
    province: string;
    country: string;
    postal_code: string;
    is_business: boolean;
    coordinates: { lat: number; lng: number } | null;
  };
  organization_urls?: {
    review?: string;
    support?: string;
  };
};

export const fetchAppointment = async (token?: string) => {
  const api = new API("galileo", token);
  const url = `/appointment`;
  const { data } = await api.get(url);
  return data as Appointment;
};
