import { useMediaQuery } from "react-responsive";

import * as S from "./styles";
import { SCREEN_SIZES } from "styles/screenSizes";

export const AddressFormPreloader = () => {
  return (
    <S.PreloaderContainer>
      <FormField />
      <FormField />
      <Map />
      <SubmitButton />
    </S.PreloaderContainer>
  );
};

function FormField() {
  return (
    <div>
      <S.Skeleton w={100} h={25} />
      <S.Skeleton w="100%" h={40} mt={12} />
    </div>
  );
}

function Map() {
  return <S.Skeleton w="100%" h={320} />;
}

function SubmitButton() {
  const isMobile = useMediaQuery({ maxWidth: SCREEN_SIZES.sm - 1 });

  return <S.Skeleton w={isMobile ? "100%" : 160} h={42} />;
}
