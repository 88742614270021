import { FC, useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { Appointment } from "@secondcloset/types/src/fulfillment";

import GoogleMapReact from "google-map-react";

import statusGroups from "constants/statusGroups";

import { useDrawRoute } from "./hooks/useDrawRoute";
import { useMapUISettings } from "./hooks/useMapUISetting";
import { HomeMarker } from "./Markers/HomeMarker";
import { TruckMarker } from "./Markers/TruckMarker";

import { Location } from "interface/location";

export const Map: FC = () => {
  const [mapRefs, setMapRefs] = useState<any>(null);
  const mapUISettings = useMapUISettings();
  const drawRoute = useDrawRoute();
  const queryClient = useQueryClient();
  const location = queryClient.getQueryData<Location>("location");
  const appointment = queryClient.getQueryData<Appointment>("appointment");
  const routePolyline = queryClient.getQueryData<string>("routePolyline");

  useEffect(() => {
    const { map, maps } = mapRefs || {};
    if (!!routePolyline && !!map && !!maps && !!maps?.geometry) {
      drawRoute({
        map,
        maps,
      });
    }
    // eslint-disable-next-line
  }, [routePolyline, mapRefs, drawRoute]);

  const renderTruckLocationMarker = () => {
    const latitude = location?.latitude || 0;
    const longitude = location?.longitude || 0;
    const map = mapRefs?.map;
    if (
      statusGroups.completed.includes(appointment?.status || "") ||
      statusGroups.failed.includes(appointment?.status || "")
    ) {
      return null;
    }
    return <TruckMarker lat={latitude} lng={longitude} map={map} />;
  };

  const renderHomeLocationMarker = () => {
    const destinationCoordinates = appointment?.location?.coordinates;
    const lat = destinationCoordinates?.lat || 0;
    const lng = destinationCoordinates?.lng || 0;
    const map = mapRefs?.map;
    return <HomeMarker lat={lat} lng={lng} map={map} />;
  };

  return (
    <GoogleMapReact
      {...(mapUISettings as any)}
      yesIWantToUseGoogleMapApiInternals
      bootstrapURLKeys={{
        key: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: ["geometry"],
      }}
      onGoogleApiLoaded={({ map, maps }) => {
        setMapRefs({ map, maps });
      }}
    >
      {renderTruckLocationMarker()}
      {renderHomeLocationMarker()}
    </GoogleMapReact>
  );
};
