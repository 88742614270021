import styled from "styled-components";

import { Table } from "antd";
import { SCREEN_SIZES } from "styles/screenSizes";
import { COLORS } from "styles";

export const ProgressTable = styled(Table)`
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-thead > tr > th {
    background-color: white;
  }

  .ant-table-tbody > tr > td {
    border-color: ${COLORS.WHITE};
  }

  .ant-table-title {
    padding-top: 24px;
  }

  @media (max-width: ${SCREEN_SIZES.md - 1}px) {
    .ant-table-thead > tr > th {
      display: none;
    }
  }

  @media (max-width: ${SCREEN_SIZES.sm - 1}px) {
    .ant-table-title {
      display: none;
    }

    .ant-table-tbody > tr > td {
      border-color: ${COLORS.GREY_LIGHT};
    }
  }
`;

export const TableTitle = styled.h2`
  font-size: 20px;
  line-height: 22px;
`;

export const UpdatedTime = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;
