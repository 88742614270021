import styled from "styled-components";

interface ColumnProps {
  flex?: number;
  alignItems?: string;
  justifyContent?: string;
  margin?: string;
  padding?: string;
  maxWidth?: string;
  overflowWrap?: string;
}

export const Column = styled.div<ColumnProps>`
  display: flex;
  flex: ${({ flex }) => (Number.isInteger(flex) ? flex : 1)};
  flex-direction: column;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "")};
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : "")};
  margin: ${({ margin }) => (margin ? margin : "")};
  padding: ${({ padding }) => (padding ? padding : "")};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "")};
  overflow-wrap: ${({ overflowWrap }) => (overflowWrap ? overflowWrap : "")};
`;
