import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";

import { InformationCircleIcon } from "@heroicons/react/solid";

import * as S from "./styles";

import { DeliveryInstructions, useDeliveryInstructions } from "components/DeliveryInstructions";
import { LanguageSwitcher } from "components/LanguageSwitcher";
import { PageBanner } from "components/PageBanner";

import { GOBOLT_SUPPORT_EMAIL } from "constants/global";
import { AddressForm, AddressFormPreloader } from "./AddressForm";
import { getShouldDisableEditingAppointmentAddress } from "./helpers";
import { useAppointment } from "./useAppointment";
import { useToken } from "./useToken";
import { useTrackingNumber } from "./useTrackingNumber";
import { useUpdateAddress } from "./useUpdateAddress";

export const EditAddressPage = () => {
  const { t } = useTranslation();
  const token = useToken();
  const trackingNumber = useTrackingNumber();
  const appointment = useAppointment();
  const updateAddress = useUpdateAddress();

  const {
    isOpen: isDeliveryInstructionsDrawerOpen,
    openDrawer: openDeliveryInstructionsDrawer,
    closeDrawer: closeDeliveryInstructionsDrawer,
  } = useDeliveryInstructions();

  if (!token || !trackingNumber) {
    return <Redirect to={"/tracking"} />;
  }

  const shouldDisableEditing = appointment.data && getShouldDisableEditingAppointmentAddress(appointment.data);

  return (
    <>
      <PageBanner pageInfo={trackingNumber} />

      <S.Container>
        <S.ContentContainer>
          {shouldDisableEditing && (
            <S.OutForDeliveryAlert>
              <InformationCircleIcon width={16} height={16} color="#FF3353" style={{ flexShrink: 0 }} />
              {t("This appointment is already out for delivery, no changes can be made")}
            </S.OutForDeliveryAlert>
          )}

          <div>
            <S.Title>{t("Update your address")}</S.Title>
            <S.Description>{t("Adjust your shipping address up until the day of your delivery.")}</S.Description>
          </div>

          {appointment.data ? (
            <AddressForm
              address={appointment.data.location}
              loading={updateAddress.isLoading}
              disabled={shouldDisableEditing}
              onSubmit={updateAddress.mutate}
            />
          ) : (
            appointment.isLoading && <AddressFormPreloader />
          )}

          <S.Divider />

          <S.DeliveryInstructionsTitle>
            {t("Add special access instructions to help us make sure your items get to you safely and securely.")}
          </S.DeliveryInstructionsTitle>

          <S.DeliveryInstructionsButton disabled={shouldDisableEditing} onClick={openDeliveryInstructionsDrawer} />

          <DeliveryInstructions.Drawer
            open={isDeliveryInstructionsDrawerOpen}
            trackingNumber={trackingNumber}
            onClose={closeDeliveryInstructionsDrawer}
          />

          <S.Divider />

          <S.Footer>
            <S.FooterTitle>{t("Having Trouble?")}</S.FooterTitle>
            <S.FooterText>
              {t("If you have any questions regarding the status of your delivery, you can contact us at")}{" "}
              <a href={`mailto:${GOBOLT_SUPPORT_EMAIL}`}>{GOBOLT_SUPPORT_EMAIL}</a>
            </S.FooterText>

            <LanguageSwitcher />
          </S.Footer>
        </S.ContentContainer>
      </S.Container>
    </>
  );
};
