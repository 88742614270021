import { HiX } from "react-icons/hi";
import styled from "styled-components";
import { COLORS } from "styles";
import { Drawer } from "antd";

export const DrawerCloseIcon = styled(HiX)`
  width: 20px;
  height: 20px;
  color: ${COLORS.BLACK};
`;

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-title {
    font-size: 20px;
  }

  .ant-drawer-header {
    padding: 13px 16px;
  }
  .ant-drawer-content {
    border-radius: 6px 6px 0 0;
  }

  .ant-drawer-close {
    margin-right: 0;
    padding: 0;
    order: 2;
  }

  .ant-drawer-body {
    padding: 0;
    overflow-y: scroll;
  }
`;
