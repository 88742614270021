import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useQueryClient } from "react-query";

import { Appointment } from "@secondcloset/types/src/fulfillment";

import dayjs from "dayjs";

import { Column, Row, Text } from "../../../../components/common";
import statusGroups from "../../../../constants/statusGroups";
import { COLORS } from "../../../../styles";

export const DeliveryTime: FC = () => {
  const queryClient = useQueryClient();
  const appointment = queryClient.getQueryData<Appointment>("appointment");
  const estimatedTravelTime = queryClient.getQueryData<number>("estimatedTravelTime");
  const location = queryClient.getQueryData<string>("location");
  const status = appointment?.status;
  const isHeavy = appointment?.delivery_type === "heavy";
  const isWideTimeSlot = appointment?.formatted_timeslot?.toUpperCase() === "9AM - 9PM";
  const formattedTimeslotStartTime = dayjs(appointment?.estimated_arrival_time_window_start).format("hA");
  const formattedTimeslotEndTime = dayjs(appointment?.estimated_arrival_time_window_end).format("hA");
  const completedTime = appointment?.completed_at;
  const { t } = useTranslation();

  const getTimeSlot = () => {
    if (
      !isHeavy ||
      !isWideTimeSlot ||
      !appointment?.estimated_arrival_time_window_start ||
      !appointment?.estimated_arrival_time_window_end
    )
      return appointment?.formatted_timeslot;
    return `${formattedTimeslotStartTime} - ${formattedTimeslotEndTime}`;
  };

  const timeSlot = getTimeSlot();
  const deliveryTime = useMemo(() => {
    if (!!status && status === "completed") {
      return dayjs(completedTime as string).format("LT");
    }
    if (!!status && status === "done") return dayjs().format("LT");
    if (!!status && statusGroups.onTheWay.includes(status)) {
      if (!estimatedTravelTime && !location) return timeSlot;
      if (!estimatedTravelTime) return dayjs().format("LT");
      return dayjs().add(estimatedTravelTime, "seconds").format("LT");
    }
    if (!!status && statusGroups.arrived.includes(status)) {
      return dayjs().format("LT");
    }
    return timeSlot;
  }, [status, estimatedTravelTime, completedTime, timeSlot, location]);

  const renderStatus = () => {
    if (!status) return <Skeleton width={80} />;
    if (status === "completed") return t("Delivery Time");
    if (statusGroups.failed.includes(status)) return t("Booked Time Slot");
    if (statusGroups.arrived.includes(status)) return t("Arrival Time");
    return t("Estimated Arrival");
  };

  const deliveryTimeSubText = useMemo(() => {
    if (
      !isHeavy ||
      !isWideTimeSlot ||
      !appointment?.estimated_arrival_time_window_start ||
      !appointment?.estimated_arrival_time_window_end ||
      status === "completed"
    )
      return;

    return t("and no later than") + ` ${appointment?.formatted_timeslot?.split(" - ")[1].trim() || "9PM"}`;
  }, [
    status,
    isHeavy,
    isWideTimeSlot,
    appointment?.formatted_timeslot,
    appointment?.estimated_arrival_time_window_end,
    appointment?.estimated_arrival_time_window_start,
    t,
  ]);

  return (
    <Row justifyContent="space-between" alignItems="flex-end" margin="0 0 16px">
      <Column>
        <Text fontSize={20} fontWeight={600} lineHeight={24}>
          {deliveryTime ? deliveryTime : <Skeleton width={80} />}
        </Text>
        {deliveryTimeSubText && (
          <Text fontSize={12} color={COLORS.GREY} lineHeight={20}>
            {deliveryTimeSubText}
          </Text>
        )}
      </Column>
      <Column alignItems="flex-end">
        <Text fontSize={12} color={COLORS.GREY} lineHeight={20}>
          {renderStatus()}
        </Text>
      </Column>
    </Row>
  );
};
