import styled from "styled-components";

interface RowProps {
  flex?: number;
  alignItems?: string;
  justifyContent?: string;
  margin?: string;
  padding?: string;
  minWidth?: string;
  cursor?: string;
  maxWidth?: string;
  overflowWrap?: string;
}

export const Row = styled.div<RowProps>`
  display: flex;
  flex: ${({ flex }) => (flex !== undefined ? flex : "initial")};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "")};
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : "")};
  margin: ${({ margin }) => (margin ? margin : "")};
  padding: ${({ padding }) => (padding ? padding : "")};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "")};
  cursor: ${({ cursor }) => (cursor ? cursor : "")};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "")};
  overflow-wrap: ${({ overflowWrap }) => (overflowWrap ? overflowWrap : "")};
`;
