import { useMutation } from "react-query";

import { API } from "lib/API";

export const useRequestAddressUpdate = () => {
  return useMutation(requestAddressUpdate);
};

type RequestAddressUpdateBody = {
  tracking_number: string;
};

function requestAddressUpdate(body: RequestAddressUpdateBody) {
  const api = new API("galileo");
  return api.post("/send_update_address_email", body);
}
