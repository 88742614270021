import React from "react";

import { Container } from "./styles";

export interface Props {
  children?: React.ReactNode;
}

export const PageContainer: React.FC<Props> = ({ children }) => {
  return <Container>{children}</Container>;
};
