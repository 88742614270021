import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const InfoText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;
