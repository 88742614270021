import styled from "styled-components";
import { DeliveryInstructions } from "components/DeliveryInstructions";
import { SCREEN_SIZES } from "styles/screenSizes";

export const Container = styled.div`
  height: calc(100vh - 85px);
  padding: 70px 16px 40px;
  overflow-y: auto;

  @media (max-width: ${SCREEN_SIZES.sm - 1}px) {
    padding-top: 40px;
    padding-bottom: 84px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 540px;
  margin: 0 auto;
`;

export const OutForDeliveryAlert = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  border-radius: 6px;
  color: #595959;
  background-color: white;
  font-size: 14px;
  line-height: 20px;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.2);
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 34px;
  font-weight: 600;
  letter-spacing: -0.68px;

  @media (max-width: ${SCREEN_SIZES.sm - 1}px) {
    font-size: 24px;
    letter-spacing: -0.24px;
  }
`;

export const Description = styled.p`
  margin: 0;
  margin-top: 12px;
  color: #6c6c6c;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
`;

export const DeliveryInstructionsTitle = styled.div`
  color: #6c6c6c;
  font-size: 18px;
  line-height: 32px;
`;

export const DeliveryInstructionsButton = styled(DeliveryInstructions.Button)`
  align-self: flex-start;
  height: 40px;
  color: #404041;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: ${SCREEN_SIZES.sm - 1}px) {
    align-self: stretch;
  }
`;

export const Footer = styled.div`
  margin-top: 8px;
`;

export const FooterTitle = styled.div`
  color: #222222;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.24px;
`;

export const FooterText = styled.div`
  margin-top: 16px;
  color: #222222;
  font-size: 16px;
  line-height: 24px;
`;

export const Divider = styled.div`
  height: 1px;
  border-top: 1px solid #cbcbcb;
`;
