import styled from "styled-components";

import { COLORS, DEVICE } from "styles";

interface OuterWrapperProps {
  screenOrientation: "landscape" | "portrait";
}

export const OuterWrapper = styled.div<OuterWrapperProps>`
  position: ${({ screenOrientation }) => (screenOrientation === "portrait" ? "absolute" : "relative")};
  display: flex;
  flex: 1;
  z-index: 10;
  top: 0;
  left: 0;
  margin: ${({ screenOrientation }) => (screenOrientation === "portrait" ? "0" : "0 0 8px")};
  padding: ${({ screenOrientation }) => (screenOrientation === "portrait" ? "8px" : "0")};
  width: 100%;

  @media ${DEVICE.lg} {
    position: relative;
    flex: 0;
    padding: 0;
    margin: 0 0 8px;
    width: 360px;
  }
`;

interface InnerWrapperProps {
  screenOrientation: "landscape" | "portrait";
}

export const InnerWrapper = styled.div<InnerWrapperProps>`
  flex-direction: column;
  width: 100%;
  padding: 16px;
  background-color: ${COLORS.WHITE};
  border-radius: 6px;
  box-shadow: ${({ screenOrientation }) =>
    screenOrientation === "portrait" ? "0px 6px 20px rgb(0 0 0 / 20%)" : "0px 2px 4px rgb(0 0 0 / 15%)"};
  overflow: hidden;

  @media ${DEVICE.lg} {
    box-shadow: 0px 2px 4px rgb(0 0 0 / 15%);
  }
`;
