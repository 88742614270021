import InformationCircleIcon from "@heroicons/react/solid/InformationCircleIcon";
import styled from "styled-components";

import { COLORS, DEVICE } from "styles";

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 152px;
  z-index: 10;
  padding: 8px 16px;
  border-radius: 6px;
  background: ${COLORS.WHITE};
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
  font-size: 9px;

  @media ${DEVICE.lg} {
    top: 24px;
    font-size: initial;
  }
`;

export const InfoIcon = styled(InformationCircleIcon)`
  margin: 0 8px 0 0;
  width: 18px;
  color: ${COLORS.ORANGE};
`;
