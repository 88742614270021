import { useLocation, useHistory } from "react-router";

import queryString from "query-string";

export interface QueryValues {
  trackingNumber?: string;
  ikeaOrderNumber?: string;
  verify?: string;
  token?: string;
  date?: string;
  cancelledAppointmentsVisible?: string;
  lng?: string;
}

export const useUrl = () => {
  const location = useLocation();
  const history = useHistory();
  const url = location.pathname;

  const getQueryValues = () => {
    return queryString.parse(location.search) as QueryValues;
  };

  const setUrlWithQueryValues = (newValues: Record<string, unknown>) => {
    const queryValues = { ...getQueryValues(), ...newValues };
    const query = queryString.stringify(queryValues);
    history.push(`${location.pathname}?${query}`);
  };

  return { url, setUrlWithQueryValues, getQueryValues };
};
