import { API } from "lib/API";

export interface FetchToken {
  postal_code: string;
  tracking_number?: string;
  ikea_order_number?: string;
}

export interface TokenResponse {
  token: string;
}

export const fetchToken = async (body: FetchToken) => {
  const api = new API("logistics");
  const { data } = await api.post("/labels/authenticate", body);

  return data as TokenResponse;
};
