import { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router";

import { useRecoilState, useResetRecoilState } from "recoil";
import { packageTrackingNumber } from "recoil/packageTracking/atoms";

import { usePackageTrackingInfo } from "hooks/api/usePackageTrackingDetails";

export const useSearchTrackingInfo = () => {
  const [trackingNumber, dispatchTrackingNumber] = useRecoilState(packageTrackingNumber);
  const resetTrackingNumber = useResetRecoilState(packageTrackingNumber);

  const history = useHistory();
  const isSearchPage = Boolean(useRouteMatch(["/tracking", "/tracking/"])?.isExact);

  const {
    data: trackingInfo,
    isError,
    isSuccess,
    refetch,
  } = usePackageTrackingInfo(trackingNumber, {
    enabled: !!trackingNumber,
    staleTime: 1200000, // 20 minutes
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      if (isSearchPage) history.push(`/tracking/${trackingNumber}`);
      resetTrackingNumber();
    },
    onError: () => {
      if (!isSearchPage) history.push("/tracking");
    },
  });

  const onClickSearch = (trackingNumber: string) => {
    dispatchTrackingNumber(trackingNumber.replace(/\s/g, ""));
  };

  useEffect(() => {
    if (!!trackingNumber && isSearchPage) refetch();
  }, [isSearchPage, refetch, trackingNumber]);

  return { isError, isSuccess, onClickSearch, trackingInfo };
};
