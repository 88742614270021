import { Input as AntdInput, Button, Checkbox } from "antd";
import styled, { createGlobalStyle } from "styled-components";
import { SCREEN_SIZES } from "styles/screenSizes";

export const GlobalStyles = createGlobalStyle`
  .address-form__autocomplete-dropdown {
    .ant-select-item.ant-select-item-option {
      padding: 12px 16px;
      color: #222222;
      font-size: 14px;

      &.ant-select-item-option-active {
        background-color: #F4F4F4;
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: #6c6c6c;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
`;

export const Input = styled(AntdInput)`
  height: 40px;
  border: 1px solid #9a9a9a;
  color: #222222;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);

  &::placeholder {
    color: #6c6c6c;
  }
`;

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 6px;
  background: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
`;

export const AddressText = styled.div`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.3px;
`;

export const AddressMapContainer = styled.div`
  height: 200px;
  border-radius: 8px;
  overflow: hidden;
`;

export const BusinessAddressCheckbox = styled(Checkbox)`
  display: flex;
  align-items: center;
  color: #3e3e3e;
  font-size: 16px;
  line-height: 16px;
  user-select: none;

  .ant-checkbox {
    font-size: 0;
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;

    &:after {
      width: 7px;
      height: 11px;
    }
  }

  .ant-checkbox-inner {
    border-radius: 2px;
  }
`;

export const UpdateAddressButton = styled(Button)`
  align-self: start;
  height: auto;
  margin-top: 8px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  @media (max-width: ${SCREEN_SIZES.sm - 1}px) {
    align-self: stretch;
  }
`;

export const PreloaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type SkeletonProps = {
  w?: string | number;
  h?: string | number;
  mt?: number;
};
export const Skeleton = styled.div.attrs<SkeletonProps>(({ w, h, mt = 0 }) => ({
  style: {
    width: w,
    height: h,
    marginTop: mt,
  },
}))<SkeletonProps>`
  border-radius: 6px;
  background-color: rgba(190, 190, 190, 0.2);
`;
