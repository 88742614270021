import styled from "styled-components";

import { SCREEN_SIZES } from "styles/screenSizes";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  overflow-y: auto;
  box-shadow: -2px 0px 4px rgb(0 0 0 / 15%);
  z-index: 2;

  @media (min-width: ${SCREEN_SIZES.lg}px) {
    max-height: 100vh;
  }
`;
