import styled from "styled-components";

interface ButtonWrapperProps {
  margin?: string;
}

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  margin: ${({ margin }) => (margin ? margin : 0)};

  button {
    border-radius: 6px;
  }
`;
