import { useQuery, useQueryClient } from "react-query";

import { Appointment } from "@secondcloset/types/src/fulfillment";

import jwtDecode from "jwt-decode";

import { useGetToken } from "hooks/application/useGetToken";

import statusGroups from "constants/statusGroups";
import { API } from "lib/API";

import { getEstimatedTravelTimeCookie, setEstimatedTravelTimeCookie } from "../../lib/cookies";

import { JwtToken } from "interface/jwtToken";
import { Location } from "interface/location";

export interface EstimatedTravelTime {
  estimatedTravelTime: number;
  updatedAt: Date;
}

export interface GetEstimatedTravelTimeBody {
  origin: string;
  destination: string;
  units?: "metric" | "imperial";
}

const getEstimatedTravelTime = async (body: GetEstimatedTravelTimeBody, token?: string): Promise<number> => {
  const api = new API("pigeon", token);
  const { data } = await api.post("/google/estimated-travel-time", body);
  return data;
};

export const useEstimatedTravelTime = () => {
  const queryClient = useQueryClient();
  const location = queryClient.getQueryData<Location>("location");
  const appointment = queryClient.getQueryData<Appointment>("appointment");
  const token = useGetToken();

  const decodedToken = token ? (jwtDecode(token) as JwtToken) : null;
  const appointmentId = decodedToken?.appointment_id;
  const cachedEstimatedTravelTime = getEstimatedTravelTimeCookie(appointmentId as string);

  const getDestination = () => {
    const lat = appointment?.location?.coordinates?.lat;
    const lng = appointment?.location?.coordinates?.lng;
    if (lat && lng) {
      return `${lat},${lng}`;
    }
    const address = appointment?.location?.address;
    const city = appointment?.location?.city;
    const province = appointment?.location?.province;
    if (address && city && province) {
      return `${address}, ${city}, ${province}`;
    }
    return "";
  };

  return useQuery(
    "estimatedTravelTime",
    () => {
      return getEstimatedTravelTime(
        {
          origin: `${location?.latitude},${location?.longitude}`,
          destination: getDestination(),
          units: "metric",
        },
        token
      );
    },
    {
      initialData:
        cachedEstimatedTravelTime?.estimatedTravelTime && cachedEstimatedTravelTime.estimatedTravelTime > 0
          ? cachedEstimatedTravelTime.estimatedTravelTime
          : undefined,
      initialDataUpdatedAt: cachedEstimatedTravelTime?.updatedAt,
      cacheTime: Infinity,
      staleTime: 1200000, // 20 minutes
      refetchInterval: 1200000, // 20 minutes
      enabled:
        !!appointment?.status &&
        statusGroups.onTheWay.includes(appointment.status) &&
        !!location?.latitude &&
        !!location?.longitude &&
        (!!appointment?.location?.coordinates ||
          (!!appointment?.location?.address && !!appointment?.location?.city && !!appointment?.location?.province)),
      onSuccess: (estimatedTravelTime) => {
        setEstimatedTravelTimeCookie(estimatedTravelTime, appointment?.id as string);
      },
    }
  );
};
