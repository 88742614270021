import { atom, DefaultValue } from "recoil";

import { setApptToken, getApptToken, removeApptToken } from "lib/cookies";

export const apptToken = atom<string | undefined>({
  key: "apptToken",
  default: undefined,
  effects_UNSTABLE: [
    ({ onSet, setSelf }) => {
      const storedToken = getApptToken();

      if (storedToken) {
        setSelf(JSON.parse(storedToken));
      }

      onSet((newApptToken) => {
        if (newApptToken instanceof DefaultValue) {
          removeApptToken();
        } else setApptToken(JSON.stringify(newApptToken));
      });
    },
  ],
});
