import { useMemo } from "react";
import { useQueryClient } from "react-query";
import { useMediaQuery } from "react-responsive";

import { Appointment } from "@secondcloset/types/src/fulfillment";

import { SCREEN_SIZES } from "styles/screenSizes";

import statusGroups from "constants/statusGroups";
import { addDistanceToCoordinate } from "lib/addDistanceToCoordinate";
import { calculateMidPoint } from "lib/calculateMidpoint";

import { removedMapStyles } from "./removedMapStyles";

import { Location } from "interface/location";

export const useMapUISettings = () => {
  const isMobile = useMediaQuery({ maxWidth: SCREEN_SIZES.lg - 1 });
  const queryClient = useQueryClient();
  const location = queryClient.getQueryData<Location>("location");
  const appointment = queryClient.getQueryData<Appointment>("appointment");

  const isAppointmentComplete =
    (!!appointment?.status &&
      (statusGroups.arrived.includes(appointment?.status) ||
        statusGroups.completed.includes(appointment?.status) ||
        statusGroups.failed.includes(appointment?.status))) ||
    !location;
  const destinationCoordinates = appointment?.location?.coordinates; // TODO: || getCoordinates(`${appointment?.location?.address}, ${appointment?.location?.city}, ${appointment?.location?.province}`)
  const midpoint = useMemo(
    () =>
      calculateMidPoint(
        { lat: location?.latitude || 0, lng: location?.longitude || 0 },
        {
          lat: destinationCoordinates?.lat || 0,
          lng: destinationCoordinates?.lng || 0,
        }
      ),
    // eslint-disable-next-line
    [destinationCoordinates?.lat]
  );
  // If mobile, move the midpoint down to accomodate for the header covering the top of the map

  // Center on the the home if the appointment is complete
  const defaultCenter = isAppointmentComplete ? { ...destinationCoordinates } : midpoint;

  if (isMobile && defaultCenter.lat) {
    if (isAppointmentComplete) {
      defaultCenter.lat = addDistanceToCoordinate(defaultCenter.lat, 100);
    } else {
      defaultCenter.lat = addDistanceToCoordinate(defaultCenter.lat, 500);
    }
  }

  const defaultZoom = isAppointmentComplete ? 16 : 10; // TODO: optimize this

  return {
    bootstrapURLKeys: { key: process.env.REACT_APP_GOOGLE_API_KEY },
    defaultCenter,
    defaultZoom,
    options: {
      styles: [...removedMapStyles],
      disableDefaultUI: true,
      clickableIcons: false,
      gestureHandling: isMobile ? "cooperative" : undefined,
    },
  };
};
