import { Route, Switch } from "react-router-dom";

import { useValidateToken } from "hooks/application/useValidateToken";

import { PageContainer } from "components/PageContainer";
import { EditAddressPage } from "pages/EditAddressPage";
import { ErrorPage } from "pages/ErrorPage/ErrorPage";
import { FeedbackAppreciatedPage } from "pages/FeedbackAppreciatedPage";
import { HomePage } from "pages/HomePage";
import { RedirectPage } from "pages/RedirectPage";
import { SignatureError } from "pages/SignatureError";
import { TrackingInfoPage } from "pages/TrackingInfoPage";
import { TrackingSearchPage } from "pages/TrackingSearchPage";

export const Router: React.FC = () => {
  useValidateToken();

  return (
    <PageContainer>
      <Switch>
        <Route exact path="/delivery-status" component={HomePage} />
        <Route exact path="/tracking/:trackingNumber" component={TrackingInfoPage} />
        <Route exact path="/tracking" component={TrackingSearchPage} />
        <Route exact path="/redirect" component={RedirectPage} />
        <Route exact path="/feedback-appreciated" component={FeedbackAppreciatedPage} />
        <Route exact path="/invalid-signature" component={SignatureError} />
        <Route exact path="/address/edit" component={EditAddressPage} />
        <Route exact path="/" component={ErrorPage} />
      </Switch>
    </PageContainer>
  );
};
