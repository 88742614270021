import styled from "styled-components";
import { SCREEN_SIZES } from "styles/screenSizes";

export const Container = styled.div`
  display: inline-flex;
  max-width: 350px;
  gap: 8px;

  @media (max-width: ${SCREEN_SIZES.sm - 1}px) {
    min-width: 100%;
  }
`;

export const TooltipWrapper = styled.div`
  .ant-tooltip-inner {
    @media (min-width: ${SCREEN_SIZES.lg}px) {
      width: 300px;
    }
    border-radius: 6px;
  }
  .ant-tooltip-arrow {
    display: none;
  }
`;
