import styled from "styled-components";

import { COLORS } from "styles";
import { SCREEN_SIZES } from "styles/screenSizes";

interface ContainerProps {
  isMonotone?: boolean;
}

export const Container = styled.header<ContainerProps>`
  position: relative;
  height: 85px;
  background-color: ${COLORS.GREEN_DARK};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  @media (max-width: ${SCREEN_SIZES.lg - 1}px) {
    height: 52px;
  }
`;

interface LogoProps {
  cursor?: string;
}

export const Logo = styled.img<LogoProps>`
  height: 50px;
  width: 127px;
  cursor: ${({ cursor }) => (cursor ? cursor : "auto")};
`;

export const Link = styled.a`
  position: absolute;
  height: 50%;
  left: 40px;
  @media (max-width: ${SCREEN_SIZES.lg - 1}px) {
    top: 14px;
    left: 20px;
  }
`;

export const PageInfo = styled.div`
  color: ${COLORS.WHITE};
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin: 0;
  @media (max-width: ${SCREEN_SIZES.lg - 1}px) {
    line-height: 24px;
    font-size: 16px;
    font-weight: 500;
  }
`;
