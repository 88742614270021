import styled from "styled-components";

import { COLORS } from "styles";
import { SCREEN_SIZES } from "styles/screenSizes";

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  margin: 0 0 24px;
  height: 56px;
  max-height: 56px;
  background: ${COLORS.WHITE};
  border-radius: 6px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 15%);

  @media (max-width: ${SCREEN_SIZES.lg - 1}px) {
    position: absolute;
    bottom: 0;
    right: 8px;
    padding: 8px;
    height: 32px;
    max-height: 32px;
    max-width: 200px;
    background: rgb(255 255 255 / 50%);
  }
`;

interface LogoProps {
  cursor?: string;
}

export const Logo = styled.img<LogoProps>`
  height: auto;
  max-height: 100%;
  width: auto;
  max-width: 148px;
  cursor: ${({ cursor }) => (cursor ? cursor : "auto")};

  @media (max-width: ${SCREEN_SIZES.lg - 1}px) {
    max-height: 16px;
    max-width: 82px;
  }
`;

export const Separator = styled.div`
  margin: 0 8px;
  height: 20px;
  border-right: 1px solid ${COLORS.CHARCOAL};
`;
