import { FC, useState } from "react";
import { useMediaQuery } from "react-responsive";

import * as S from "./styles";
import { SCREEN_SIZES } from "styles/screenSizes";

import { Column, Row, Text } from "components/common";

interface Props {
  title: string;
  renderIcon?: () => JSX.Element;
}

export const MenuItem: FC<Props> = ({ title, renderIcon, children }) => {
  const isNotMobile = useMediaQuery({ minWidth: SCREEN_SIZES.lg });
  const [isOpen, setIsOpen] = useState(isNotMobile ? true : false);

  return (
    <S.Wrapper>
      <Column>
        <S.ClickableMenuBar
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <Row alignItems="center">
            {!!renderIcon && renderIcon()}
            <Text fontSize={16} fontWeight={400}>
              {title}
            </Text>
          </Row>
          <S.MenuItemIcon isOpen={isOpen} />
        </S.ClickableMenuBar>
        <S.Collapsible isOpen={isOpen}>{children}</S.Collapsible>
      </Column>
    </S.Wrapper>
  );
};
