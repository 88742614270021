import styled from "styled-components";

import { COLORS, DEVICE } from "styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media ${DEVICE.lg} {
    flex-direction: row;
  }
`;

export const MapWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  min-height: calc(100vh * 2 / 3);
  max-height: calc(100vh * 2 / 3);
  position: relative;

  @media ${DEVICE.lg} {
    max-height: none;
    flex: 1;
  }
`;

export const MapSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .spin-icon {
    font-size: 30px;
    color: ${COLORS.RED};
  }
`;

interface ButtonWrapperProps {
  margin?: string;
}

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  margin: ${({ margin }) => (margin ? margin : 0)};
`;
