import * as S from "./styles";

type MessageCardProps = {
  mainMessage: string;
  subMessage: string;
};
export const MessageCard = ({ mainMessage, subMessage }: MessageCardProps) => {
  return (
    <S.Container>
      <S.MainText>{mainMessage}</S.MainText>
      <S.SubText>{subMessage}</S.SubText>
    </S.Container>
  );
};
