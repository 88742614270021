import { COLORS } from "styles";

import { atom } from "recoil";

export const colorState = atom<string>({
  key: "colorState",
  default: COLORS.BLUE,
});

export const isColorPickerOpenState = atom<boolean>({
  key: "isColorPickerOpenState",
  default: false,
});
