import { FC } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useQueryClient } from "react-query";

import { Appointment } from "@secondcloset/types/src/fulfillment";

import lowerCase from "lodash/lowerCase";
import startCase from "lodash/startCase";

import * as S from "./styles";

import { useRecoilValue } from "recoil";
import { colorState } from "recoil/colourPicker/atoms";

import { Column, Row, Text } from "components/common";
import { AppointmentUtils } from "lib/AppointmentUtils";
import { formatPhoneNumber } from "lib/formatPhoneNumber";

import { MenuItem } from "../MenuItem";

export const ShippingDetails: FC = () => {
  const color = useRecoilValue(colorState);
  const queryClient = useQueryClient();
  const appointment = queryClient.getQueryData<Appointment>("appointment");
  const { t } = useTranslation();

  const name = startCase(lowerCase(appointment?.source?.customer?.name)) || null;
  const address = AppointmentUtils.getFormattedAddress(appointment);
  const phoneNumber = formatPhoneNumber(appointment?.source?.customer?.phone_number);
  const email = appointment?.source?.customer?.email_address || null;
  const values = [name, address, phoneNumber, email];

  const renderValue = (value: string | null | undefined, index: number) => {
    if (!appointment || value === undefined) {
      return <Skeleton key={index} width={140} />;
    }
    if (value) {
      return (
        <Row key={index} margin="0 0 4px">
          <Text>{value}</Text>
        </Row>
      );
    }
  };

  return (
    <MenuItem title={t("Shipping Details")} renderIcon={() => <S.CarIcon color={color} />}>
      <Column padding="0 16px 16px">{values.map((value, index) => renderValue(value, index))}</Column>
    </MenuItem>
  );
};
