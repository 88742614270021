import { useEffect } from "react";

import { notifyNewVersion } from "lib/notifyNewVersion";

export const useCheckForUpdate = () => {
  useEffect(() => {
    const checkForUpdate = async () => {
      const registration = await navigator?.serviceWorker?.getRegistration();
      if (!registration) return;
      // no update pending; check swr for updates
      else if (!registration.waiting) return await registration.update();
      // update is pending for installation; show notification
      else return notifyNewVersion(registration);
    };

    // persistent notification when update is available
    checkForUpdate();

    window.addEventListener("focus", checkForUpdate);
    return () => window.removeEventListener("focus", checkForUpdate);

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
};
