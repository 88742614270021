import { FC } from "react";
import { useMediaQuery } from "react-responsive";

import * as S from "./styles";
import { SCREEN_SIZES } from "styles/screenSizes";

import { useRecoilValue } from "recoil";
import { screenOrientationState } from "recoil/screenOrientation/atoms";

import { Column } from "components/common";
import { CompanyLogos } from "components/CompanyLogos";
import { LanguageSwitcher } from "components/LanguageSwitcher";

import { Header } from "../Header";

import { OrderDetails } from "./OrderDetails";
import { ShippingDetails } from "./ShippingDetails/ShippingDetails";
import { SmsToggle } from "./SmsToggle";
import { Support } from "./Support";

export const Menu: FC = () => {
  const screenOrientation = useRecoilValue(screenOrientationState);
  const isNotMobile = useMediaQuery({ minWidth: SCREEN_SIZES.lg });

  return (
    <S.Wrapper>
      {isNotMobile ? <CompanyLogos /> : null}
      {screenOrientation === "landscape" || isNotMobile ? <Header /> : null}
      <Column>
        <OrderDetails />
        <ShippingDetails />
        <Support />
        <SmsToggle />
      </Column>
      <LanguageSwitcher />
    </S.Wrapper>
  );
};
