import InformationCircleIcon from "@heroicons/react/outline/InformationCircleIcon";
import MailIcon from "@heroicons/react/outline/MailIcon";
import PhoneIcon from "@heroicons/react/outline/PhoneIcon";
import QuestionMarkCircleIcon from "@heroicons/react/outline/QuestionMarkCircleIcon";
import styled from "styled-components";

import { COLORS } from "styles";

interface PhoneIconProps {
  color: string;
  width: number;
}

export const Phone = styled(PhoneIcon)<PhoneIconProps>`
  width: ${({ width }) => (width ? `${width}px` : "")};
  color: ${({ color }) => color};
  margin: 0 8px 0 0;
`;

export const InfoCircleIcon = styled(InformationCircleIcon)`
  margin: 0 8px 0 0;
  width: 20px;
  color: ${COLORS.BLUE};
`;

export const QuestionCircleIcon = styled(QuestionMarkCircleIcon)`
  margin: 0 8px 0 0;
  width: 20px;
  color: ${COLORS.BLUE};
`;

export const ButtonWrapper = styled.div`
  width: 100%;

  button {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

export const EnvelopeIcon = styled(MailIcon)`
  margin: 0 8px 0 0;
  width: 20px;
  color: ${COLORS.WHITE};
`;

export const Link = styled.a`
  color: ${COLORS.BLUE};
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    color: ${COLORS.BLUE_LIGHT};
  }
`;
