import { FC } from "react";
import { useMediaQuery } from "react-responsive";

import * as S from "./styles";
import { SCREEN_SIZES } from "styles/screenSizes";

import { ShipmentProgressTable } from "./ShipmentProgressTable/ShipmentProgressTable";
import { TableMobileDrawer } from "./TableMobileDrawer";

import { TransitStep } from "interface/packageTrackingInfo";
interface ShipmentProgressProps {
  progressEvents: TransitStep[];
  visible: boolean;
  onClose: () => void;
}

export const ShipmentProgress: FC<ShipmentProgressProps> = ({ progressEvents, visible, onClose }) => {
  const isMobile = useMediaQuery({ maxWidth: SCREEN_SIZES.sm - 1 });

  return isMobile ? (
    <TableMobileDrawer
      visible={visible}
      onClose={onClose}
      table={<ShipmentProgressTable isMobile={isMobile} progressEvents={progressEvents} />}
    />
  ) : (
    <S.Container>
      <ShipmentProgressTable isMobile={isMobile} progressEvents={progressEvents} />
    </S.Container>
  );
};
