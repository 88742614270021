import { useMutation } from "react-query";
import { useHistory } from "react-router";

import { notification } from "antd";

import { useResetRecoilState, useSetRecoilState } from "recoil";
import { apptToken } from "recoil/application/atoms";

import { fetchToken } from "./fetchToken";

export const useValidatePostalCode = () => {
  const dispatchApptToken = useSetRecoilState(apptToken);
  const resetApptToken = useResetRecoilState(apptToken);
  const history = useHistory();

  const {
    mutate: validate,
    isLoading,
    isSuccess,
  } = useMutation(fetchToken, {
    onSuccess: (data) => {
      dispatchApptToken(data.token);
      history.push("/delivery-status");
    },
    onError: (e: string) => {
      resetApptToken();
      notification.error({
        message: "Error",
        description: e,
      });
    },
  });

  return { validate, isLoading, isSuccess };
};
