import TruckIcon from "@heroicons/react/outline/TruckIcon";
import styled from "styled-components";

interface CarIconProps {
  color: string;
}

export const CarIcon = styled(TruckIcon)<CarIconProps>`
  width: 24px;
  color: ${({ color }) => color};
  margin: 0 8px 0 0;
`;
