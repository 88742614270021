import { TransitFlowStep } from "interface/packageTrackingInfo";

export const getCurrentFlowSequence = (currentLabelStatus: string, transitFlow: TransitFlowStep[]) => {
  // We map label_received_at_destination to label_scanned because label_received_at_destination is purposely not apart of transit_flow.
  // However, we want to show it as apart of "Processing Shipment" since it is considered to be under the same part of the flow label_scanned
  const mappedStatus = currentLabelStatus === "label_received_at_destination" ? "label_scanned" : currentLabelStatus;
  return transitFlow.find((step) => step.name === mappedStatus)?.sequence || 0;
};

function shouldExcludeCity(text?: string) {
  return text?.toLowerCase()?.startsWith("package received in") ? true : false;
}

export function buildDescriptionWithCity(description?: string) {
  if (!description) return { desc: "", city: "" };
  let desc = description;
  let city = "";
  if (shouldExcludeCity(description)) {
    const position = description.lastIndexOf(" ");
    desc = description.substring(0, position);
    city = description.substring(position);
  }
  return { desc, city };
}

const options: Intl.DateTimeFormatOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

// Returns this format: Friday, October 13, 2021
export function buildReadableDate(dateStr: Date) {
  return dateStr.toLocaleDateString("en-US", options);
}
