import { useHistory, useLocation } from "react-router";

import queryString from "query-string";

export const useDeliveryInstructions = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const openDrawer = () => {
    const queryParams = queryString.parse(history.location.search);

    history.push({
      ...history.location,
      search: queryString.stringify({ ...queryParams, delivery_notes: true }),
    });
  };

  const closeDrawer = () => {
    const queryParams = queryString.parse(history.location.search);

    history.push({
      ...history.location,
      search: queryString.stringify({ ...queryParams, delivery_notes: undefined }),
    });
  };

  return {
    isOpen: queryParams.delivery_notes === "true",
    openDrawer,
    closeDrawer,
  };
};
