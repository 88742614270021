import React from "react";
import { useMediaQuery } from "react-responsive";

import { Button } from "antd";

import * as S from "./styles";
import { SCREEN_SIZES } from "styles/screenSizes";

export interface TrackingInfoCardProps {
  children: React.ReactNode;
  buttonText?: string;
  onClick?: () => void;
}

export const TrackingInfoCard: React.FC<TrackingInfoCardProps> = ({ children, buttonText, onClick }) => {
  const isMobile = useMediaQuery({ maxWidth: SCREEN_SIZES.sm - 1 });

  return (
    <S.Container>
      {children}
      {isMobile && !!buttonText && (
        <S.ButtonWrapper>
          <Button type="link" onClick={onClick} style={{ padding: "0px" }}>
            {buttonText}
          </Button>
        </S.ButtonWrapper>
      )}
    </S.Container>
  );
};
