// import { Appointment } from "@secondcloset/types/src/fulfillment";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useQueryClient } from "react-query";

import { COLORS } from "styles";

// import { useRecoilValue } from "recoil";
// import { colorState } from "recoil/colourPicker/atoms";

import { Column, Row, Text } from "components/common";
import statusGroups from "constants/statusGroups";
import { AppointmentUtils } from "lib/AppointmentUtils";

import { Appointment } from "interface/appointment";

import { buildDescriptionWithCity } from "pages/TrackingInfoPage/helpers";

export const Status: FC = () => {
  const queryClient = useQueryClient();
  const appointment = queryClient.getQueryData<Appointment>("appointment");
  // const color = useRecoilValue(colorState);
  const { t, i18n } = useTranslation();
  const status = appointment?.status as string;
  const jobType = appointment?.job_type;
  const positionInLine = appointment?.position_in_line;
  const isReloAppointment = AppointmentUtils.checkIsReloAppointment(appointment);

  const renderStatus = (description?: string) => {
    const { desc, city } = buildDescriptionWithCity(description || "");
    if (!status) return <Skeleton width={140} />;
    if (description) return <Text fontSize={16}>{t(desc) + ` ${city}`}</Text>;
    if (positionInLine) {
      if (positionInLine === 1) {
        return <Text fontSize={16}>{t("You're next!")}</Text>;
      }
      // return (
      //   <Text fontSize={16}>
      //     {t("Handling")}
      //     <Text fontSize={16} color={color}>
      //       {`${positionInLine - 1} ${t(`shipment${positionInLine - 1 > 1 ? "s" : ""} before yours`)}`}
      //     </Text>
      //   </Text>
      // );
    }
  };

  const renderNote = (note: string) => {
    return (
      <Text fontSize={14} color={COLORS.GREY}>
        {!!status ? t(note) : <Skeleton width={240} />}
      </Text>
    );
  };

  const statusAndNote = useMemo(() => {
    const statusMap = {
      default: {
        status: renderStatus(t("Order received")),
        note: renderNote(t("Come back to this page on the day of delivery")),
      },
      ready: {
        status: renderStatus(),
        note: renderNote(t("We're preparing your package")),
      },
      loaded: {
        status: renderStatus(),
        note: renderNote(t("Your package has been dispatched!")),
      },
      close: {
        status: renderStatus(),
        note: renderNote(t("This is a good time to prepare for your delivery")),
      },
      onTheWay: {
        status: renderStatus(t("We're on our way!")),
        note: renderNote(t("This is a good time to prepare for your delivery")),
      },
      arrived: {
        status: renderStatus(t("We're here!")),
        note:
          jobType === "delivery"
            ? renderNote(t("We're delivering your package now"))
            : renderNote(t("Please meet us outside if you're home")),
      },
      completed: {
        status: renderStatus(t(`${isReloAppointment ? "Picked Up" : "Delivered"}`)),
        note: renderNote(t("Have a great day!")),
      },
      failed: {
        status: renderStatus(t("Delivery Unsuccessful")),
        note: renderNote(t("We will reschedule it for you")),
      },
    };

    if (statusGroups.ready.includes(status)) return statusMap.ready;
    if (statusGroups.loaded.includes(status)) {
      if (positionInLine && positionInLine < 3) return statusMap.close;
      return statusMap.loaded;
    }
    if (statusGroups.onTheWay.includes(status)) return statusMap.onTheWay;
    if (statusGroups.arrived.includes(status)) return statusMap.arrived;
    if (statusGroups.completed.includes(status)) return statusMap.completed;
    if (statusGroups.failed.includes(status)) return statusMap.failed;
    return statusMap.default;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, positionInLine, i18n.language]);

  return (
    <Column>
      <Row margin="0 0 4px">{statusAndNote.status}</Row>
      <Row>{statusAndNote.note}</Row>
    </Column>
  );
};
