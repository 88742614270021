import { useTranslation } from "react-i18next";

import { Button } from "antd";
import styled from "styled-components";

import { COLORS } from "styles";

type DeliveryInstructionsButtonProps = {
  disabled?: boolean;
  className?: string;
  onClick: () => void;
};

export const DeliveryInstructionsButton = ({ disabled, className, onClick }: DeliveryInstructionsButtonProps) => {
  const { t } = useTranslation();

  return (
    <StyledButton disabled={disabled} className={className} onClick={onClick}>
      {t("Add delivery instructions")}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  border: 1px solid ${COLORS.GREY_DARK};
`;
