import React from "react";

import TruckIcon from "@heroicons/react/outline/TruckIcon";

import * as S from "./styles";
import { COLORS } from "styles";

interface Props {
  lat: number;
  lng: number;
  map: any;
}

export const TruckMarker: React.FC<Props> = () => {
  return (
    <S.ImageWrap>
      <TruckIcon style={{ width: "16px", height: "16px", color: COLORS.WHITE }} />
    </S.ImageWrap>
  );
};
