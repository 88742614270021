import { FC } from "react";

import * as S from "./styles";
import { COLORS } from "styles";

import { Row, Text, Column } from "../../../../components/common";

import EvIcon from "./assets/EV-label.svg";

export const ElectricVehicleCallout: FC = () => {
  return (
    <Column>
      <S.Divider />
      <Row alignItems="center" margin="24px 0 0 0">
        <Column flex={0}>
          <S.ElectricVehicleIconWrapper>
            <S.ElectricVehicleIcon src={EvIcon}></S.ElectricVehicleIcon>
          </S.ElectricVehicleIconWrapper>
        </Column>
        <Column margin="4px 0 0 8px" flex={1}>
          <Text fontSize={12} fontWeight={400} lineHeight={20} color={COLORS.GREY} letter-spacing={20}>
            Your package is being delivered with zero emissions with our electric vehicle
          </Text>
        </Column>
      </Row>
    </Column>
  );
};
