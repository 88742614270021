import styled from "styled-components";

import { COLORS } from "styles";

export const ImageWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: translate(-50%, -100%);
  position: absolute;
  z-index: 10;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${COLORS.BLACK};
`;

export const Image = styled.img``;
