import { FC } from "react";

import { Space, Typography } from "antd";

import { StyledErrorBoundaryFallback } from "components/ErrorBoundaryFallback/styles";

import { HelpCentreLink } from "../../components/HelpCentreLink";

export const SignatureError: FC = () => {
  return (
    <StyledErrorBoundaryFallback
      status="403"
      title="Your link has expired!"
      subTitle={
        <Space direction="vertical">
          <Typography.Text type="secondary" strong>
            If it&apos;s been more than 48 hours since the day of your delivery, then this is expected! We expire old
            delivery tracking links for your security.
          </Typography.Text>
          <Typography.Text type="secondary" strong>
            Otherwise, you can visit the would like to contact us, please contact customer support at <HelpCentreLink />
            .
          </Typography.Text>
        </Space>
      }
    />
  );
};
