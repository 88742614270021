import styled from "styled-components";
import { Button } from "antd";
import XIcon from "@heroicons/react/outline/XIcon";
import ExclamationCircleIcon from "@heroicons/react/outline/ExclamationCircleIcon";
import { COLORS } from "styles";

export const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid ${COLORS.SURFACE_BORDER};
`;

export const DrawerTitle = styled.h3`
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
`;

export const DrawerCloseButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  padding: 8px;
`;

export const DrawerCloseButtonIcon = styled(XIcon)`
  width: 24px;
  height: 24px;
`;

export const DrawerBody = styled.div`
  padding: 32px 24px;
`;

export const Warning = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 4px;
  color: #704819;
  background-color: #fff2df;
  font-size: 14px;
  line-height: 23px;
`;

export const WarningIcon = styled(ExclamationCircleIcon)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`;
