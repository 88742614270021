import { initReactI18next } from "react-i18next";

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { langaugeSettingCookieName } from "config/cookieName";

import en from "../locales/en.json";
import fr from "../locales/fr.json";
import "dayjs/locale/en";
import "dayjs/locale/fr";

dayjs.extend(localizedFormat);

i18n.on("initialized", () => {
  dayjs.locale(i18n.language);
});

i18n.on("languageChanged", (lang) => {
  dayjs.locale(lang);
});

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      detection: {
        // order to detect the user language from
        order: ["querystring", "cookie", "localStorage", "navigator"],

        // keys or params to lookup language from
        lookupQuerystring: "lng",
        lookupCookie: langaugeSettingCookieName,
        lookupLocalStorage: langaugeSettingCookieName,
        lookupFromPathIndex: 0,
        lookupFromSubdomainIndex: 0,

        // cache user language on
        caches: ["cookie", "localStorage"],
        excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

        // optional expire and domain for set cookie
        cookieMinutes: 43200, // 30 days

        // optional htmlTag with lang attribute, the default is:
        htmlTag: document.documentElement,
      },
      ns: ["translation"],
      defaultNS: "translation",
      supportedLngs: ["en", "fr"],
      fallbackLng: "en",
      debug: process.env.NODE_ENV === "development" ? true : false,
      keySeparator: ".",
      interpolation: {
        formatSeparator: ",",
      },
      react: {
        useSuspense: false,
      },
      resources: {
        en,
        fr,
      },
    },
    (err) => {
      if (err) return console.error("Something went wrong loading i18n", err);
    }
  );

// eslint-disable-next-line import/no-default-export
export default i18n;
