import { useQuery } from "react-query";

import { API } from "lib/API";

import { useGetToken } from "../application/useGetToken";

const getLastLocation = async (token: string) => {
  const api = new API("pigeon", token);
  const { data } = await api.post("/location-logs/last");
  return data;
};

export const useLocation = () => {
  const token = useGetToken();

  return useQuery("location", () => token && getLastLocation(token), {
    refetchInterval: 30000, // 30s
    enabled: !!token,
    retry: false,
    // TODO: Implement vibrate when browser support is better https://caniuse.com/mdn-api_navigator_vibrate
    // onSuccess: ({ latitude, longitude }) => { // vibrate when < 0.5km away. add hasVibrated to global state and update here
    //   const location = appointment?.location;
    //   if (location) {
    //     const { lat, lng } = location?.coordinates;
    //     const distance = calculateDistance(latitude, longitude, lat, lng);
    //     if (distance < 0.5) {
    //       window.navigator.vibrate([200, 30, 200, 30, 200]);
    //     }
    //   }
    // },
  });
};
