import styled from "styled-components";
import { COLORS } from "../../styles";
import { HiSearch } from "react-icons/hi";
import { SCREEN_SIZES } from "styles/screenSizes";

export const PageContainer = styled.div`
  height: 60vh;
  background: ${COLORS.GREEN_DARK};
  display: flex;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  z-index: 100;
  margin: 150px 25px 0;
  width: fit-content;

  @media (max-width: ${SCREEN_SIZES.md - 1}px) {
    margin-top: 50px;
  }
`;

export const Title = styled.h1`
  font-size: 20px;
  color: ${COLORS.GOLD};

  @media (max-width: ${SCREEN_SIZES.sm - 1}px) {
    font-size: 16px;
  }
`;

export const Tagline = styled.div`
  color: ${COLORS.WHITE};
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;

  @media (max-width: ${SCREEN_SIZES.sm - 1}px) {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }
`;

export const BackgroundImg = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  min-width: 100vw;
  max-height: 40vh;
  overflow: hidden;
`;

export const SearchInputIcon = styled(HiSearch)`
  width: 16px;
  height: 16px;
  color: ${COLORS.GREY_DARK};

  @media (max-width: ${SCREEN_SIZES.md - 1}px) {
    width: 12px;
    height: 12px;
  }
`;

export const SearchContainer = styled.div`
  display: inline-flex;
  gap: 8px;
  margin: 24px 0 8px 0;
  width: 100%;
  .ant-input-affix-wrapper {
    border-color: ${COLORS.GREEN_DARK};
  }
  .ant-input-affix-wrapper:hover {
    border-color: ${COLORS.GREEN_LIGHT};
  }
  .ant-input-affix-wrapper:focus {
    border-color: ${COLORS.GREEN_LIGHT};
  }
  .ant-input {
    border-radius: 1px;
  }
  .ant-input::selection {
    background: ${COLORS.GREEN_PRIMARY};
  }
  .ant-btn-primary {
    background-color: ${COLORS.GREEN_PRIMARY};
    border-color: ${COLORS.GREEN_PRIMARY};
  }
  .ant-btn-primary:hover {
    background-color: ${COLORS.GREEN_LIGHT};
  }
  .ant-btn-primary:focus {
    background-color: ${COLORS.GREEN_LIGHT};
  }
`;

export const LanguageSwitcherContainer = styled.div`
  position: fixed;
  left: 50%;
  bottom: 10px;
  translate: -50%;
`;
