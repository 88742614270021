import { useQueryClient } from "react-query";

import { Appointment } from "@secondcloset/types/src/fulfillment";

import { COLORS } from "styles";

import statusGroups from "constants/statusGroups";

interface Props {
  map: any;
  maps: any;
}

export const useDrawRoute = () => {
  const queryClient = useQueryClient();
  const appointment = queryClient.getQueryData<Appointment>("appointment");
  const routePolyline = queryClient.getQueryData<string>("routePolyline");
  if (!routePolyline) return () => null;

  return ({ map, maps }: Props) => {
    if (
      !!map &&
      !!maps?.geometry?.encoding &&
      appointment?.status &&
      statusGroups.onTheWay.includes(appointment?.status)
    ) {
      const arrayPath = maps.geometry.encoding.decodePath(routePolyline);

      const routePath = new maps.Polyline({
        path: arrayPath,
        geodesic: true,
        strokeColor: COLORS.BLUE,
        strokeWeight: 3,
      });

      routePath.setMap(map);
    }
  };
};
