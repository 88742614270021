import { useAppointment } from "../api/useAppointment";
import { useEstimatedTravelTime } from "../api/useEstimatedTravelTime";
import { useLocation } from "../api/useLocation";
import { useRoutePolyline } from "../api/useRoutePolyline";

export const useInitialize = () => {
  useAppointment();
  useLocation();
  useRoutePolyline();
  useEstimatedTravelTime();
};
