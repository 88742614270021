import styled from "styled-components";
import { COLORS } from "styles";
import { SCREEN_SIZES } from "styles/screenSizes";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${COLORS.WHITE};
  border-radius: 6px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  padding: 30px 50px;

  @media (max-width: ${SCREEN_SIZES.md - 1}px) {
    margin-top: 2px;
    padding: 10px 16px;
    height: 40vh;
  }

  @media (max-width: ${SCREEN_SIZES.sm - 1}px) {
    height: 60vh;
  }
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  border-top: 1px solid ${COLORS.GREY_MID};
  width: 100%;

  .ant-btn > span {
    padding-top: 5px;
    text-decoration-line: underline;
  }
`;
