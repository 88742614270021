import pinImage from "./pin.png";

type PinProps = {
  lat: number;
  lng: number;
};

export const Pin = (_: PinProps) => {
  return <img src={pinImage} width={26} alt="" style={{ translate: "-50% -100%" }} />;
};
