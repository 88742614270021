import styled from "styled-components";

import { DEVICE } from "styles";

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  @media ${DEVICE.lg} {
    flex: none;
    width: 360px;
  }
`;
