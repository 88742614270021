import styled from "styled-components";
import { Steps } from "antd";
import { SCREEN_SIZES } from "styles/screenSizes";

export const TransitFlowTracker = styled(Steps)`
  padding-top: 6px;
  margin-left: 14px;

  @media (max-width: ${SCREEN_SIZES.xl - 1}px) {
    margin-left: -22px;
    .ant-steps-item:last-child {
      max-width: 15%;
    }
  }

  @media (max-width: ${SCREEN_SIZES.md - 1}px) {
    width: 80vw;
    height: 40vh;

    .ant-steps-item:last-child {
      max-height: 44px;
    }
  }
`;

export const TransitStep = styled(Steps.Step)`
  .ant-steps-item-content {
    padding-left: 8px;
    max-width: fit-content;
  }

  .ant-steps-item-icon {
    width: fit-content;
  }
  .ant-steps-item-tail {
    padding: 6px 0 !important;
  }

  .ant-steps-item-tail::after {
    height: 120% !important;
    padding: 3px;
  }
`;

export const DeliveredTime = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;
