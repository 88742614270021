import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useMediaQuery } from "react-responsive";

import { Button, Input, Tooltip } from "antd";

import * as S from "./styles";
import { SCREEN_SIZES } from "styles/screenSizes";

import { useValidatePostalCode } from "hooks/api/useValidatePostalCode";

import { PackageTrackingInfo } from "interface/packageTrackingInfo";

interface ValidatePostalCodeFieldProps {
  currentFlowSequence: number;
}

export const ValidatePostalCodeField: FC<ValidatePostalCodeFieldProps> = ({ currentFlowSequence }) => {
  const { t } = useTranslation();
  const [postalCode, setPostalCode] = useState("");
  const isDesktop = useMediaQuery({ minWidth: SCREEN_SIZES.lg });
  const isDisabled = !![0, 1, 2].find((s) => s === currentFlowSequence);

  const { validate } = useValidatePostalCode();
  const queryClient = useQueryClient();
  const trackingNumber = queryClient.getQueryData<PackageTrackingInfo>("packageTracking")?.tracking_number || "";

  const onClick = (postalCode: string) => {
    validate({ postal_code: postalCode, tracking_number: trackingNumber });
  };

  return (
    <S.TooltipWrapper>
      <Tooltip
        title={t("Live tracking will be available when your package is on its way to you")}
        placement={isDesktop ? "right" : "top"}
        trigger={isDisabled ? ["hover", "focus"] : []}
        getPopupContainer={(triggerNode) => triggerNode}
      >
        <S.Container>
          <Input
            autoFocus
            placeholder={t("Enter Postal Code")}
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
            onPressEnter={() => onClick(postalCode)}
            disabled={isDisabled}
          />
          <span style={{ cursor: "not-allowed" }}>
            <div style={{ pointerEvents: isDisabled ? "none" : "auto" }}>
              <Button
                type="primary"
                style={{ borderRadius: "6px" }}
                onClick={() => onClick(postalCode)}
                disabled={isDisabled}
              >
                {t("Live Track")}
              </Button>
            </div>
          </span>
        </S.Container>
      </Tooltip>
    </S.TooltipWrapper>
  );
};
