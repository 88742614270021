import CubeIcon from "@heroicons/react/outline/CubeIcon";
import styled from "styled-components";

interface PackageIconProps {
  color?: string;
}

export const PackageIcon = styled(CubeIcon)<PackageIconProps>`
  width: 24px;
  color: ${({ color }) => color};
  margin: 0 8px 0 0;
`;
