import { FC, useMemo } from "react";
import { useQueryClient } from "react-query";
import BarLoader from "react-spinners/BarLoader";

import { Appointment } from "@secondcloset/types/src/fulfillment";

import * as S from "./styles";

import { useRecoilValue } from "recoil";

import { Row } from "../../../../components/common";
import statusGroups from "../../../../constants/statusGroups";
import { colorState } from "../../../../recoil/colourPicker/atoms";
import { COLORS } from "../../../../styles";

export const ProgressBar: FC = () => {
  const color = useRecoilValue(colorState);
  const queryClient = useQueryClient();
  const appointment = queryClient.getQueryData<Appointment>("appointment");

  const progressBarStatuses = useMemo(() => {
    const status = appointment?.status;
    if (!status) return [false, false, false, false];
    if (statusGroups.completed.includes(status)) {
      return [true, true, true, true];
    }
    if (statusGroups.onTheWay.includes(status) || statusGroups.arrived.includes(status)) {
      return [true, true, true, false];
    }
    if (statusGroups.loaded.includes(status)) {
      return [true, true, false, false];
    }
    if (statusGroups.ready.includes(status)) {
      return [true, false, false, false];
    }
    return [false, false, false, false];
  }, [appointment]);

  const renderProgressBars = () => {
    return progressBarStatuses.map((isFilled, index) => {
      const isLast = index + 1 === progressBarStatuses.length;
      const isInProgress = isFilled && !isLast && !progressBarStatuses[index + 1];
      return isInProgress ? (
        <S.BarLoaderWrapper key={index}>
          <BarLoader color={COLORS.BLUE} loading width="0px" height={4} />
        </S.BarLoaderWrapper>
      ) : (
        <S.Bar key={index} color={color} isFilled={isFilled} />
      );
    });
  };

  return (
    <Row justifyContent="space-between" margin="0 0 16px">
      {renderProgressBars()}
    </Row>
  );
};
