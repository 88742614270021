import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import * as S from "./styles";
import { SCREEN_SIZES } from "styles/screenSizes";

import { GoBoltLogo } from "../GoBoltLogo";

interface PageBannerProps {
  pageInfo?: string;
  isMonotone?: boolean;
}

export const PageBanner: FC<PageBannerProps> = ({ pageInfo, isMonotone }) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery({ minWidth: SCREEN_SIZES.lg });
  const isMobile = useMediaQuery({ minWidth: SCREEN_SIZES.sm - 1 });

  const renderTrackingNumber = () => {
    const prefix = t(isDesktop ? "Tracking Number" : "Tracking #");
    const separator = isDesktop ? ": " : " - ";

    return <S.PageInfo>{`${prefix}${separator}${pageInfo}`}</S.PageInfo>;
  };

  const isPageInfoVisible = !!pageInfo && isMobile;

  return (
    <S.Container isMonotone={isMonotone}>
      <S.Link href={"https://gobolt.com"} target={"_blank"} rel="noreferrer">
        <GoBoltLogo isMonotone={isMonotone} />
      </S.Link>
      {isPageInfoVisible && renderTrackingNumber()}
    </S.Container>
  );
};
