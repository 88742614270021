import { FC } from "react";
import { useMediaQuery } from "react-responsive";

import { COLORS } from "styles";
import { SCREEN_SIZES } from "styles/screenSizes";

interface GoBoltLogoProps {
  isMonotone?: boolean;
}

export const GoBoltLogo: FC<GoBoltLogoProps> = ({ isMonotone }) => {
  const isDesktop = useMediaQuery({ minWidth: SCREEN_SIZES.lg });
  const fillColor = isMonotone ? COLORS.WHITE : COLORS.GOLD;
  return (
    <svg
      width={isDesktop ? 128 : 64}
      height={isDesktop ? 50 : 25}
      viewBox="0 0 128 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84.828 38.1639C84.2686 38.4384 83.4855 38.5755 82.4799 38.577L76.8467 38.584L76.8397 32.3914L82.5499 32.3843C83.5456 32.3928 84.3176 32.5229 84.8686 32.775C85.8532 33.2258 86.3468 34.0546 86.3496 35.2629C86.351 36.6911 85.8434 37.6585 84.828 38.1639ZM76.8286 23.4251L81.8744 23.4195C82.9862 23.4181 83.8994 23.5372 84.6169 23.7779C85.4475 24.1238 85.8643 24.8379 85.8657 25.92C85.8657 26.8959 85.5524 27.5764 84.9259 27.9614C84.298 28.3451 83.4799 28.5382 82.4729 28.5396L76.8342 28.5466L76.8286 23.4251ZM87.6544 30.14C88.4321 29.7395 89.0221 29.2985 89.4221 28.8141C90.1368 27.9517 90.4934 26.8119 90.492 25.3936C90.4906 24.0173 90.1327 22.8356 89.4221 21.85C88.2404 20.2441 86.2364 19.4222 83.4072 19.383L72.1953 19.397L72.2246 42.6233L82.6786 42.6106C83.8561 42.6078 84.9497 42.5029 85.9553 42.29C86.9621 42.0801 87.8349 41.6894 88.5761 41.121C89.2319 40.6267 89.7788 40.0163 90.2165 39.2911C90.9032 38.1989 91.2458 36.964 91.2444 35.5877C91.243 34.2535 90.9395 33.1194 90.3368 32.1856C89.7327 31.2517 88.8376 30.5699 87.6544 30.14Z"
        fill={fillColor}
      />
      <path d="M111.411 42.5738L115.896 42.5682L115.867 19.342L111.381 19.3475L111.411 42.5738Z" fill={fillColor} />
      <path
        d="M127.017 28.7351L127.012 25.5359L124.226 25.5401L124.221 20.7505L119.783 20.756L119.789 25.5457L117.396 25.5485L117.401 28.7477L119.792 28.7436L119.806 39.3171C119.807 40.3883 120.06 41.182 120.564 41.6959C121.342 42.5038 122.791 42.8692 124.909 42.7936L127.035 42.711L127.031 39.3549C126.882 39.3661 126.734 39.3746 126.582 39.3788C126.429 39.3858 126.286 39.3871 126.149 39.3871C125.237 39.3885 124.69 39.3031 124.512 39.1294C124.333 38.9573 124.244 38.519 124.242 37.8148L124.231 28.7379L127.017 28.7351Z"
        fill={fillColor}
      />
      <path
        d="M101.161 38.6302C98.5831 38.633 96.4912 36.544 96.4884 33.9637C96.4856 31.3833 98.5724 29.2902 101.148 29.286C103.725 29.2832 105.818 31.3721 105.82 33.9525C105.825 36.5328 103.738 38.6275 101.161 38.6302ZM101.144 24.7748C96.0772 24.7818 91.9769 28.8981 91.9824 33.9707C91.9894 39.0418 96.101 43.1483 101.167 43.1413C106.232 43.1343 110.333 39.018 110.328 33.9469C110.322 28.8757 106.21 24.7692 101.144 24.7748Z"
        fill={fillColor}
      />
      <path
        d="M61.4005 38.6302C58.8231 38.633 56.731 36.544 56.7282 33.9637C56.7253 31.3833 58.8119 29.2902 61.388 29.286C63.9654 29.2832 66.0576 31.3721 66.0604 33.9525C66.0646 36.5328 63.9781 38.6275 61.4005 38.6302ZM61.3837 24.7748C56.317 24.7818 52.2165 28.8981 52.2222 33.9707C52.2292 39.0418 56.3407 43.1483 61.4062 43.1413C66.4715 43.1343 70.5734 39.018 70.5678 33.9469C70.5608 28.8757 66.4492 24.7692 61.3837 24.7748Z"
        fill={COLORS.WHITE}
      />
      <path
        d="M30.6155 -9.53674e-06L5.7488 19.4395H11.9655L2.1416 30.5808H11.8887L30.6155 -9.53674e-06Z"
        fill={fillColor}
      />
      <path
        d="M0 50L24.8667 30.5605H18.65L28.4739 19.4192H18.7267L0 50Z"
        fill={isMonotone ? COLORS.WHITE : COLORS.GOLD_DARK}
      />
      <path
        d="M51.1615 35.3432V29.696H43.2699V34.067H46.5978V36.2787L46.5023 36.3601C46.4172 36.4328 44.3836 38.1404 41.5485 38.1404C39.6397 38.1404 37.8852 37.4386 36.6081 36.1643C35.2437 34.8029 34.5224 32.917 34.5224 30.7105C34.5224 28.7685 35.1082 27.0997 36.2161 25.8843C37.4741 24.5046 39.3864 23.7753 41.7462 23.7753C44.5642 23.7753 46.7113 25.2619 47.4164 25.8186L49.8962 21.9469C49.6061 21.6885 48.9126 21.1243 47.8296 20.5659C46.4807 19.8704 44.2334 19.0415 41.1861 19.0415C37.8067 19.0415 34.7242 20.2552 32.5061 22.459C30.311 24.6402 29.1021 27.6408 29.1021 30.9084C29.1021 34.4728 30.2915 37.5223 32.5418 39.7275C34.6127 41.7566 37.4018 42.8742 40.3954 42.8742C44.1563 42.8742 46.3862 40.9182 46.4081 40.8988L46.4855 40.8291H46.5996V42.512H51.1726V35.3432H51.1615Z"
        fill={COLORS.WHITE}
      />
    </svg>
  );
};
