import { useMutation, useQueryClient } from "react-query";

import { Appointment } from "@secondcloset/types/src/fulfillment";

import { useGetToken } from "hooks/application/useGetToken";

import { API } from "lib/API";

interface UpdateSmsSettingsBody {
  smsSetting: boolean;
}

const updateSmsSettings = async ({ smsSetting }: UpdateSmsSettingsBody, token?: string) => {
  const api = new API("galileo", token);
  const url = `/appointment`;
  const { data } = await api.put(url, { sms: smsSetting });
  return data as Appointment;
};

export const useUpdateSmsSettings = () => {
  const queryClient = useQueryClient();
  const token = useGetToken();

  return useMutation((body: UpdateSmsSettingsBody) => updateSmsSettings(body, token), {
    onSuccess: () => {
      queryClient.refetchQueries(["appointment"]);
    },
  });
};
